import { lazy } from "react";

// interface components
import routerHandlersType from "../Types";

const routeHandlers: Record<string, routerHandlersType> = {

    // pricing components

    Onboarding: {
      url: "/pricing/onboarding",
      componentTitle: "",
      component: lazy(() => import("../Pages/OnBoarding/OnBoarding")),
    },
  
  // Home components
  Home: {
    url: "/home",
    componentTitle: "",
    component: lazy(() => import("../Pages/Patient/Home/index")),
  },
  MyAccountInformation: {
    url: "/my-account-information",
    componentTitle: "",
    component: lazy(() => import("../Pages/Patient/Home/MyAccountInformation")),
  },

  // ConnectMyMedicalRecords components
  ConnectMyMedicalRecords: {
    url: "/connect-my-medical-records",
    componentTitle: "Connect my Medical Records",
    component: lazy(
      () => import("../Pages/Patient/ConnectMyMedicalRecords/index")
    ),
  },
  ConnectMyMedicalRecordsHealthSystem: {
    url: "/connect-my-medical-records/health-system",
    componentTitle: "Connect my Medical Records",
    component: lazy(
      () => import("../Pages/Patient/ConnectMyMedicalRecords/HealthSystem")
    ),
    prevLink: "/connect-my-medical-records",
  },

  // ShareMyMedicalRecords components
  ShareMyMedicalRecords: {
    url: "/share-my-medical-records",
    componentTitle: "Share my Medical Records",
    component: lazy(
      () => import("../Pages/Patient/ShareMyMedicalRecords/index")
    ),
  },
  ShareMyMedicalRecordsFindProviders: {
    url: "/share-my-medical-records/find-providers",
    componentTitle: "Share my Medical Records",
    component: lazy(
      () => import("../Pages/Patient/ShareMyMedicalRecords/FindProviders")
    ),
    prevLink: "/share-my-medical-records",
  },
  ShareMyMedicalRecordsSelectProvider: {
    url: "/share-my-medical-records/find-providers/select-provider",
    componentTitle: "Share my Medical Records",
    component: lazy(
      () => import("../Pages/Patient/ShareMyMedicalRecords/SelectProvider")
    ),
    prevLink: "/share-my-medical-records/find-providers",
  },
  ShareMyMedicalRecordsShareRecord: {
    url: "/share-my-medical-records/find-providers/select-provider/share-record",
    componentTitle: "Share my Medical Records",
    component: lazy(
      () => import("../Pages/Patient/ShareMyMedicalRecords/ShareRecord")
    ),
    prevLink: "/share-my-medical-records/find-providers/select-provider",
  },
  ShareMyMedicalRecordsShareRecordToOtherContact: {
    url: "/share-my-medical-records/share-record-to-other-contacts",
    componentTitle: "Share my Medical Records",
    component: lazy(
      () =>
        import(
          "../Pages/Patient/ShareMyMedicalRecords/ShareRecordToOtherContacts"
        )
    ),
    prevLink: "/share-my-medical-records",
  },

  // Profile components
  PatientDemographics: {
    url: "/profile/patient-demographics",
    componentTitle: "Profile",
    component: lazy(
      () => import("../Pages/Patient/Profile/PatientDemographics/index")
    ),
    prevLink:"/home"
  },
  PatientDemographicsFoodSecurity: {
    url: "/profile/patient-demographics/food-security",
    componentTitle: "Food Security",
    component: lazy(
      () => import("../Pages/Patient/Profile/PatientDemographics/Details")
    ),
    prevLink: "/profile/patient-demographics",
  },
  AllergiesAndIntolerances: {
    url: "/profile/allergies-and-intolerance",
    componentTitle: "Allergies and Intolerances",
    component: lazy(
      () => import("../Pages/Patient/Profile/AllergiesAndIntolerances/index")
    ),
    prevLink:"/home"
  },
  AllergiesAndIntolerancesDetails: {
    url: "/profile/allergies-and-intolerance/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/Profile/AllergiesAndIntolerances/Details")
    ),
    prevLink: "/profile/allergies-and-intolerance",
  },
  Vaccinations: {
    url: "/profile/vaccinations",
    componentTitle: "Vaccinations",
    component: lazy(
      () => import("../Pages/Patient/Profile/Vaccinations/index")
    ),
    prevLink: "/home",
  },
  VaccinationsDetails: {
    url: "/profile/vaccinations/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/Profile/Vaccinations/Details")
    ),
    prevLink: "/profile/vaccinations",
  },
  SmokingStatus: {
    url: "/profile/smoking-status",
    componentTitle: "Smoking Status",
    component: lazy(
      () => import("../Pages/Patient/Profile/SmokingStatus/index")
    ),
    prevLink: "/home",
  },
  SmokingStatusDetails: {
    url: "/profile/smoking-status/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/Profile/SmokingStatus/Details")
    ),
    prevLink: "/profile/smoking-status",
  },

  // Care teams components
  CareTeams: {
    url: "/care-team/care-teams",
    componentTitle: "Care Teams",
    component: lazy(() => import("../Pages/Patient/CareTeam/CareTeams/index")),
    prevLink: "/home",
  },
  CarePlan: {
    url: "/care-team/care-teams/care-plan",
    componentTitle: "Care Team",
    component: lazy(
      () => import("../Pages/Patient/CareTeam/CareTeams/CarePlan")
    ),
    prevLink: "/care-team/care-teams",
  },
  CarePlanDetails: {
    url: "/care-team/care-teams/care-plan/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/CareTeam/CareTeams/Details")
    ),
    prevLink: "/care-team/care-teams/care-plan",
  },
  CarePlanerDetails: {
    url: "/care-team/care-teams/care-plan/details/care-planner-details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/CareTeam/CareTeams/CarePlanerDetails")
    ),
    prevLink: "/care-team/care-teams/care-plan/details",
  },
  Providers: {
    url: "/care-team/providers",
    componentTitle: "Providers",
    component: lazy(() => import("../Pages/Patient/CareTeam/Providers/index")),
    prevLink: "/home",
  },
  ProvidersDetails: {
    url: "/care-team/providers/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/CareTeam/Providers/Details")
    ),
    prevLink: "/care-team/providers",
  },

  // Care history components
  Conditions: {
    url: "/care-history/conditions",
    componentTitle: "Conditions",
    component: lazy(
      () => import("../Pages/Patient/CareHistory/Conditions/index")
    ),
    prevLink: "/home",
  },
  ConditionsDetails: {
    url: "/care-history/conditions/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/CareHistory/Conditions/Details")
    ),
    prevLink: "/care-history/conditions",
  },
  Encounters: {
    url: "/care-history/encounters",
    componentTitle: "Encounters",
    component: lazy(
      () => import("../Pages/Patient/CareHistory/Encounters/index")
    ),
    prevLink: "/home",
  },
  EncountersDetails: {
    url: "/care-history/encounters/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/CareHistory/Encounters/Details")
    ),
    prevLink: "/care-history/encounters",
  },
  TreatmentPlan: {
    url: "/care-history/treatment-plan",
    componentTitle: "Treatment Plans",
    component: lazy(
      () => import("../Pages/Patient/CareHistory/TreatmentPlan/index")
    ),
    prevLink: "/home",
  },
  TreatmentPlanDetails: {
    url: "/care-history/treatment-plan/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/CareHistory/TreatmentPlan/Details")
    ),
    prevLink: "/care-history/treatment-plan",
  },

  // Medication components
  Medications: {
    url: "/medications/medications",
    componentTitle: "Medications",
    component: lazy(() => import("../Pages/Patient/Medications/index")),
    prevLink: "/home",
  },
  MedicationsDetails: {
    url: "/medications/medications/request-refill",
    componentTitle: "",
    component: lazy(() => import("../Pages/Patient/Medications/RequestRefill")),
    prevLink: "/medications/medications",
  },

  // Procedure components
  Procedures: {
    url: "/procedures/procedures",
    componentTitle: "Procedures",
    component: lazy(
      () => import("../Pages/Patient/Procedures/Procedures/index")
    ),
    prevLink: "/home",
  },
  ProceduresDetails: {
    url: "/procedures/procedures/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/Procedures/Procedures/Details")
    ),
    prevLink: "/procedures/procedures",
  },
  Devices: {
    url: "/procedures/devices",
    componentTitle: "Devices",
    component: lazy(() => import("../Pages/Patient/Procedures/Devices/index")),
    prevLink: "/home",
  },
  DevicesDetails: {
    url: "/procedures/devices/details",
    componentTitle: "Feeding Tubes",
    component: lazy(
      () => import("../Pages/Patient/Procedures/Devices/Details")
    ),
    prevLink: "/procedures/devices",
  },

  // Health Testing components
  HealthScreen: {
    url: "/health-testing/health-screen",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/HealthScreen/index")
    ),
  },
  HealthScreenBars: {
    url: "/health-testing/health-screen/bars",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/HealthScreen/Bars")
    ),
    prevLink: "/health-testing/health-screen",
  },
  HealthScreenBarDetails: {
    url: "/health-testing/health-screen/bars/bar-details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/HealthScreen/BarDetails")
    ),
    prevLink: "/health-testing/health-screen/bars",
  },
  HealthScreenDetails: {
    url: "/health-testing/health-screen/bars/bar-details/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/HealthScreen/Details")
    ),
    prevLink: "/health-testing/health-screen/bars/bar-details",
  },

  HealthScreenFilterDetails: {
    url: "/health-testing/health-screen/bars/bar-details/details/filter-details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/HealthScreen/FilterDetails")
    ),
    prevLink: "/health-testing/health-screen/bars/bar-details/details",
  },
  LabTest: {
    url: "/health-testing/lab-test",
    componentTitle: "Lab Tests",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/LabTest/index")
    ),
    prevLink: "/home"
  },
  LabTestDetails: {
    url: "/health-testing/lab-test/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/LabTest/Details")
    ),
    prevLink: "/health-testing/lab-test",
  },
  LabTestDetailsPercentDetails: {
    url: "/health-testing/lab-test/details/percent-details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/LabTest/PercentDetails")
    ),
    prevLink: "/health-testing/lab-test",
  },
  LabPanels: {
    url: "/health-testing/lab-panels",
    componentTitle: "Lab Panels",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/LabPanels/index")
    ),
    prevLink: "/home"
  },
  LabPanelsDetails: {
    url: "/health-testing/lab-panels/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/LabPanels/Details")
    ),
    prevLink: "/health-testing/lab-panels",
  },
  LabPanelsDetailsPercentDetails: {
    url: "/health-testing/lab-panels/details/percent-details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/LabPanels/PercentDetails")
    ),
    prevLink: "/health-testing/lab-panels/details",
  },
  Vitals: {
    url: "/health-testing/vitals",
    componentTitle: "Vitals",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/Vitals/index")
    ),
    prevLink: "/home"
  },
  VitalsDetails: {
    url: "/health-testing/vitals/details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/Vitals/Details")
    ),
    prevLink: "/health-testing/vitals",
  },
  VitalsFilterDetails: {
    url: "/health-testing/vitals/details/filter-details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/Vitals/FilterDetails")
    ),
    prevLink: "/health-testing/vitals/details",
  },
  VitalsListingDetails: {
    url: "/health-testing/vitals/details/filter-details/listing-details",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/HealthTesting/Vitals/ListingDetails")
    ),
    prevLink: "/health-testing/vitals",
  },

  // Learning health system components
  // LearningHealthSystem: {
  //   url: "/learning-health-system",
  //   componentTitle: "Dashboard",
  //   component: lazy(() => import("../Pages/Patient/Home/index")),
  // },

  // Goal components
  // Goal: {
  //   url: "/goal",
  //   componentTitle: "Goal",
  //   component: lazy(() => import("../Pages/Patient/Home/index")),
  // },

  // My Virtual care components
  MyVirtualCareHome: {
    url: "/my-virtual-care/home",
    componentTitle: "Patient Home",
    component: lazy(() => import("../Pages/Patient/MyVirtualCare/Home")),
    prevLink:"/home"
  },
  Schedule_Appointment: {
    url: "/my-virtual-care/schedule-appointment",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/MyVirtualCare/ScheduleAppointment")
    ),
  },
  MyVirtualCareProviders: {
    url: "/my-virtual-care/my-providers",
    componentTitle: "Patient Home",
    component: lazy(() => import("../Pages/Patient/MyVirtualCare/MyProviders")),
    prevLink:"/home"
  },
  MyVirtualCareAppointments: {
    url: "/my-virtual-care/my-appointments",
    componentTitle: "Patient Home",
    component: lazy(
      () => import("../Pages/Patient/MyVirtualCare/MyAppointments")
    ),
    prevLink:"/home"
  },
  Video_Chat: {
    url: "/my-virtual-care/video-chat",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/MyVirtualCare/VideoChat/VideoChat")
    ),
  },

  // My Forms components

  MyForms: {
    url: "/my-Forms",
    componentTitle: "Patient Home",
    component: lazy(() => import("../Pages/Patient/MyForms/index")),
    prevLink: "/home",
  },
  MedicalHistory: {
    url: "/medical-history",
    componentTitle: "Patient Home",
    component: lazy(
      () => import("../Pages/Patient/MyForms/MedicalHistory/MedicalHistory")
    ),
        prevLink: "/home",
  },
  HIPAAConscentFrom: {
    url: "/hippa-consent-form",
    componentTitle: "Patient Home",
    component: lazy(
      () => import("../Pages/Patient/MyForms/HIPPAConsentForm/HIPPAConsentForm")
    ),
    prevLink: "/home",
  },
  TelehealthConsentForm: {
    url: "/telehealth-consent-form",
    componentTitle: "Patient Home",
    component: lazy(
      () =>
        import(
          "../Pages/Patient/MyForms/TelehealthConsentForm/TelehealthConsentForm"
        )
    ),
    prevLink: "/home",
  },
  InformationReleseAuthorization: {
    url: "/release-authorization-form",
    componentTitle: "Patient Home",
    component: lazy(
      () =>
        import(
          "../Pages/Patient/MyForms/InformationReleseAuthorization/InformationReleseAuthorization"
        )
    ),
    prevLink: "/home",
  },
  // My medication components
  MyMedicationHome: {
    url: "/my-medication",
    componentTitle: "Request Refill",
    component: lazy(() => import("../Pages/Patient/MyMedicationHome/index")),
    prevLink : "/medications/medications"
  },

  AddNewMedication: {
    url: "/my-medication/add-new-medication",
    componentTitle: "my Medication",
    component: lazy(
      () => import("../Pages/Patient/MyMedicationHome/AddNewMedication")
    ),
  },

  MedicationDetail: {
    url: "/my-medication/details",
    componentTitle: "my Medication",
    component: lazy(
      () => import("../Pages/Patient/MyMedicationHome/MedicationDetail")
    ),
  },
  MedicationRefillLocation: {
    url: "/my-medication/refilllocation",
    componentTitle: "my Medication",
    component: lazy(
      () => import("../Pages/Patient/MyMedicationHome/RefillLocation")
    ),
  },
  MedicationDetailConfirmRefillLocation: {
    url: "/my-medication/confirmlocation",
    componentTitle: "my Medication",
    component: lazy(
      () => import("../Pages/Patient/MyMedicationHome/ConfirmRefillLocation")
    ),
  },
  MedicationChat: {
    url: "/my-medication/chat",
    componentTitle: "my Medication",
    component: lazy(() => import("../Pages/Patient/MyMedicationHome/Chat")),
  },
  MedicationConfirmationBooking: {
    url: "/my-medication/confirmlocation-booking",
    componentTitle: "my Medication",
    component: lazy(
      () => import("../Pages/Patient/MyMedicationHome/ConfirmationBooking")
    ),
  },
  MedicationCancellationBooking: {
    url: "/my-medication/Cancellation-booking",
    componentTitle: "my Medication",
    component: lazy(
      () => import("../Pages/Patient/MyMedicationHome/CancellationBooking")
    ),
  },
  // My connected devices components
  MyConnectedDevices: {
    url: "/my-connected-devices",
    componentTitle: "",
    component: lazy(() => import("../Pages/Patient/MyConnectedDevices/index")),
  },

  MyWeather: {
    url: "/my-weather",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/MyConnectedDevices/Weather")
    ),
  },
  // My patient EHR portals components
  MyPatientEHRPortals: {
    url: "/EHR-portals/my-portals",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/MyPatientEHRPortals/MyPortals")
    ),
  },
  SearchPatientEHRPortals: {
    url: "/EHR-portals/search-portals",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Patient/MyPatientEHRPortals/SearchPortals")
    ),
  },

  // My patient well-being components
  MyWellBeingInitial: {
    url: "/well-being",
    componentTitle: "my Well-Being",
    component: lazy(() => import("../Pages/Patient/WellBeing/MyWellBeing")),
  },
  MyWellBeingProvider: {
    url: "/well-being/provider",
    componentTitle: "Back",
    component: lazy(() => import("../Pages/Patient/WellBeing/SelectProvider")),
    prevLink: "/well-being",
  },
  MyWellBeingAdvisert: {
    url: "/well-being/advise-list",
    componentTitle: "Back",
    component: lazy(
      () => import("../Pages/Patient/WellBeing/WellBeingAdviseList")
    ),
    prevLink: "/well-being/provider",
  },
  MyWellBeingGoal: {
    url: "/well-being/goal",
    componentTitle: "Back",
    component: lazy(() => import("../Pages/Patient/WellBeing/WellBeingGoal")),
    prevLink: "/well-being/advise-list",
  },
  //Provider-Home routes
  Provider_Home: {
    url: "/provider-home",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/Home/Home")),
  },
  Provider_InvitePatient: {
    url: "/invite-patient",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/Home/InvitePatient/InvitePatient")
    ),
  },
  Provider_Appointments: {
    url: "/provider-appointments",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/Home/Appointment/Appointment")
    ),
  },

  Provider_Task: {
    url: "/provider-task-summary",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/Tasks/ProviderTask")),
  },
  Provider_Prescribe: {
    url: "/provider-prescribe",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/Prescribe/ProviderPrescribe")
    ),
  },
  Provider_EHR: {
    url: "/provider-Ehr",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/EHR/ProviderEHR")),
  },
  // provider charting routes
  Provider_Charting: {
    url: "/provider-Charting",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/ChartingAndTranscription/index")
    ) as any,
  },
  Provider_Charting_And_Transcription: {
    url: "/provider-Charting-and-Transcription",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Provider/ChartingAndTranscription/ChartingTrans/ChartingTrans"
        )
    ),
  },
  Provider_Charting_ERX: {
    url: "/provider-Charting-Erx",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Provider/ChartingAndTranscription/ChartingPages/ChartingErx/ChartingErx"
        )
    ),
  },

  Provider_Charting_Session: {
    url: "/provider-Charting-Session",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Provider/ChartingAndTranscription/ChartingPages/ChartingSession/ChartingSession"
        )
    ),
  },
  Provider_Charting_Auto: {
    url: "/provider-Charting-Auto",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Provider/ChartingAndTranscription/ChartingPages/ChartingAuto/ChartingAuto"
        )
    ),
  },
  Provider_Charting_NOMs: {
    url: "/provider-Charting-NOMS",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Provider/ChartingAndTranscription/ChartingPages/ChartingNOMS/ChartinNOMS"
        )
    ),
  },
  Provider_Charting_HCPCS: {
    url: "/provider-Charting-HCPCS",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Provider/ChartingAndTranscription/ChartingPages/ChartingHCPCS/ChartingHCPCS"
        )
    ),
  },

  //  provide task routes
  Provider_Create_Task: {
    url: "/provider-create-task",
    componentTitle: "Create Referral",
    component: lazy(() => import("../Pages/Provider/Tasks/CreateTask")),
    prevLink: "/provider-create-task",
  },
  Provider_Task_coding: {
    url: "/provider-task-coding",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/Tasks/ProviderTaskCoding")),
  },
  Provider_Task_Notes: {
    url: "/provider-task-notes",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/Tasks/ProviderTaskNotes")),
  },
  Provider_Task_Rx: {
    url: "/ ",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/Tasks/ProviderTaskRx")),
  },
  //Provider-Profile routes
  Provider_Profile: {
    url: "/provider-profile",
    componentTitle: "Provider Home",
    component: lazy(() => import("../Pages/Provider/Profile/index")),
    prevLink: "/provider-appointments",
  },
  Provider_Schedule: {
    url: "/provider-schedule",
    componentTitle: "Provider Home",
    component: lazy(
      () => import("../Pages/Provider/Profile/ScheduleHome/ScheduleHome")
    ),
    prevLink: "/provider-appointments",
  },
  Provider_Leave_Request: {
    url: "/provider-leave-request",
    componentTitle: "Leave Request",
    component: lazy(
      () => import("../Pages/Provider/Profile/LeaveRequests/LeaveRequest")
    ),
    prevLink: "/provider-appointments",
  },

  Provider_Reschedule: {
    url: "/provider-profile/reschedule",
    componentTitle: "Schedule",
    component: lazy(() => import("../Pages/Provider/Profile/Reschedule")),
  },

  //Provider-Consultant routes
  Provider_Consultant: {
    url: "/provider-consultant",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/AcceptConsultant/index")),
  },
  Provider_Consultant_Profile: {
    url: "/provider-consultant/profile",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/AcceptConsultant/ConsultDetail/Profile")
    ),
  },
  Provider_Consultant_SessionHistory: {
    url: "/provider-consultant/session-history",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Provider/AcceptConsultant/ConsultDetail/SessionHistory"
        )
    ),
  },
  Provider_Consultant_HomeDevices: {
    url: "/provider-consultant/home-devices",
    componentTitle: "",
    component: lazy(
      () =>
        import("../Pages/Provider/AcceptConsultant/ConsultDetail/HomeDevices")
    ),
  },

  Provider_Consultant_Weather: {
    url: "/provider-consultant/weather",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/AcceptConsultant/ConsultDetail/Weather")
    ),
  },

  Provider_Consultant_Glucode_Meter: {
    url: "/provider-consultant/glucose-meter",
    componentTitle: "",
    component: lazy(
      () =>
        import("../Pages/Provider/AcceptConsultant/ConsultDetail/GlucoseMeter")
    ),
  },
  // provider Video Session routes
  Provider_VideoSession_Profile: {
    url: "/provider-videosession/profile",
    // componentTitle: "Chat",
    component: lazy(() => import("../Pages/Provider/VideoSession/Profile")),
    // prevLink: "/provider-appointments",
  },
  Provider_VideoSession_SessionHistory: {
    url: "/provider-videosession/session-history",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/VideoSession/SessionHistory")
    ),
  },
  Provider_VideoSession_Appointments: {
    url: "/provider-videosession/appointment",
    componentTitle: "",
    component: lazy(() => import("../Pages/Provider/VideoSession/Appointment")),
  },
  Provider_VideoSession_SessionDetail: {
    url: "/provider-videosession/session-detail",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/VideoSession/SessionDetail")
    ),
  },

  //provider Appointment Routes

  Provider_Appointments_Index: {
    url: "/provider-appointments/schedule-appointments",
    componentTitle: "Provider Home",
    component: lazy(() => import("../Pages/Provider/Appointments/Appointment")),
    prevLink: "/provider-appointments",
  },
  Provider_Appointments_Detail: {
    url: "/provider-appointments/appointments-detail",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/Appointments/AppointmentDetail")
    ),
  },
  Provider_Appointments_ScheduleUserAppointments: {
    url: "/provider-appointments/schedule-user-appointments",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/Appointments/ScheduleUserAppointments")
    ),
  },
  Provider_Appointments_RescheduleRequest: {
    url: "/provider-appointments/reschedule-request",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Provider/Appointments/RescheduleRequest")
    ),
  },
  Provider_Forms: {
    url: "/provider/patient-form",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/PatientForms/PatientForms")
     
    ),
    prevLink: "/provider/patient-profile",
  },
  Provider_Patient_MedicalHistory: {
    url: "/provider/patient-medical-history",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/PatientForms/MedicalHistory")
    ),
    prevLink: "/provider/patient-profile",
  },
  Provider_Patient_HippaConsentForm: {
    url: "/provider/patient-hippaconsent-form",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/PatientForms/HIPPAConsentForm")
    ),
    prevLink: "/provider/patient-profile",
  },
  Provider_Patient_TelehealthConsentForm: {
    url: "/provider/patient-telehealthconsent-form",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/PatientForms/TelehealthConsentForm")
    ),
    prevLink: "/provider/patient-profile",
  },
  Provider_Patient_InformationAuthorization: {
    url: "/provider/patient-release-authorization",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/PatientForms/InformationReleseAuthorization")
    ),
    prevLink: "/provider/patient-profile",
  },
  Provider_AddPatient_contact: {
    url: "/provider/edit-contact-history",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/PatientContactHistory")
    ),
    prevLink: "/provider/patient-profile",
  },
  Provider_Patient_profile: {
    url: "/provider/patient-profile",
    componentTitle: "Provider Home",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/PatientProfile")
    ),
     prevLink: "/provider-appointments",
  },
  Provider_DetailProfile: {
    url: "/provider/patient-detail",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/DetailsProfile")
    ),
    prevLink: "/provider/patient-profile",
  },
  Provider_EditProfile: {
    url: "/provider/edit-patient-profile",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Provider/Home/PatientProfile/EditPatientProfile")
    ),
    prevLink: "/provider/patient-profile",
  },

  //Admin  routes

  //Admin-Home routes
  Admin_Home: {
    url: "/admin/home",
    componentTitle: "",
    component: lazy(() => import("../Pages/Admin/Home/Home")),
  },
  Admin_HomeTaskListing: {
    url: "/admin/homelisting",
    componentTitle: "",
    component: lazy(() => import("../Pages/Admin/Home/HomeTaskListing")),
  },
  Admin_Profile: {
    url: "/admin/profile",
    componentTitle: "Admin Home",
    component: lazy(() => import("../Pages/Admin/Home/Profile")),
    prevLink: "/admin/homelisting",
  },
  // Admin_AddPatient: {
  //   url: "/admin/add-patient",
  //   componentTitle: "Patient Profile",
  //   // component: lazy(() => import("../Pages/Admin/AddPatient/AddPatient")),
  //   prevLink: "/admin/patient-profile",
  // },
  Admin_AddProvider: {
    url: "/admin/add-provider",
    componentTitle: "Provider Profiles",
    component: lazy(() => import("../Pages/Admin/AddProvider/AddProvider")),
    prevLink: "/admin/provider-profile",
  },
  Admin_Schedule: {
    url: "/admin/schedule",
    componentTitle: "Admin Home",
    component: lazy(() => import("../Pages/Admin/Schedule/AdminSchedule/AdminSchedule")),
    prevLink: "/admin/homelisting",
  },
  All_Admin_Profile: {
    url: "/admin/admin-profile",
    componentTitle: "Admin Home",
    component: lazy(() => import("../Pages/Admin/AdminProfile/AdminProfile")),
    prevLink: "/admin/homelisting",
  },
  View_Admin_Profile: {
    url: "/admin/view-admin-profile",
    componentTitle: "Admin Profiles",
    component: lazy(() => import("../Pages/Admin/AdminProfile/ViewAdminProfile")),
    prevLink: "/admin/admin-profile",
  },
  Admin_Scheduling: {
    url: "/admin/scheduling",
    componentTitle: "Admin Home",
    component: lazy(
      () => import("../Pages/Admin/Schedule/NewSchedule/NewSchedule")
    ),
    prevLink: "/admin/homelisting",
  },
  Admin_Users: {
    url: "/admin/scheduling/users",
    componentTitle: "",
    component: lazy(
      () => import("../Pages/Admin/Schedule/NewSchedule/User/User")
    ),
    prevLink: "/admin/scheduling",
  },
  Admin_Leave: {
    url: "/admin/scheduling/leave",
    componentTitle: "Scheduling",
    component: lazy(
      () => import("../Pages/Admin/Schedule/NewSchedule/ScheduleLeave/Leave")
    ),
    prevLink: "/admin/scheduling",
  },
  // admin task-management
  Admin_TaskManagement: {
    url: "/admin/task-management",
    componentTitle: "Referrals Templates",
    component: lazy(
      () => import("../Pages/Admin/TaskManagement/TaskMangement")
    ),
    prevLink: "/admin/task-management",
  },
  Admin_AddNewTaskManagement: {
    url: "/admin/task-management/add-new-template",
    componentTitle: "Referrals Templates + New Template",
    component: lazy(
      () => import("../Pages/Admin/TaskManagement/AddNewTask/AddNewTask")
    ),
    prevLink: "/admin/task-management",
  },
  Admin_CreateTaskManagement: {
    url: "/admin/task-management/create-task",
    componentTitle: "Create Task",
    component: lazy(
      () => import("../Pages/Admin/TaskManagement/CreateTask/CreateTask")
    ),
    prevLink: "/admin/task-management/create-task",
  },
  Admin_AssignTaskManagement: {
    url: "/admin/task-management/assign-task",
    componentTitle: "Assign Task",
    component: lazy(
      () => import("../Pages/Admin/TaskManagement/AssignTask/AssignTask")
    ),
    prevLink: "/admin/task-management/create-task",
  },
  //Admin-Patient Profile routes
  Admin_PatientProfile: {
    url: "/admin/patient-profile",
    componentTitle: "Admin Home",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/PatientProfile")
    ),
    prevLink: "/admin/homelisting",
  },
  Admin_DetailProfile: {
    url: "/admin/patient-detail",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/DetailsProfile")
    ),
    prevLink: "/admin/patient-profile",
  },
  Admin_EditProfile: {
    url: "/admin/edit-patient-profile",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/EditPatientProfile")
    ),
    prevLink: "/admin/patient-profile",
  },
  Admin_Forms: {
    url: "/admin/patient-form",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/PatientForms/PatientForms")
    ),
    prevLink: "/admin/patient-profile",
  },
  Admin_Patient_MedicalHistory: {
    url: "/admin/patient-medical-history",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/PatientForms/MedicalHistory")
    ),
    prevLink: "/admin/patient-profile",
  },
  Admin_Patient_HippaConsentForm: {
    url: "/admin/patient-hippaconsent-form",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/PatientForms/HIPPAConsentForm")
    ),
    prevLink: "/admin/patient-profile",
  },
  Admin_Patient_TelehealthConsentForm: {
    url: "/admin/patient-telehealthconsent-form",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/PatientForms/TelehealthConsentForm")
    ),
    prevLink: "/admin/patient-profile",
  },
  Admin_Patient_InformationAuthorization: {
    url: "/admin/patient-release-authorization",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/PatientForms/InformationReleseAuthorization")
    ),
    prevLink: "/admin/patient-profile",
  },
  


  //Admin-Provider Schedule rules
  Admin_ProviderScheduleRules: {
    url: "/admin/schedule-rules",
    componentTitle: "Provider Profiles",
    component: lazy(
      () => import("../Pages/Admin/ProviderScheduleRules/ProviderScheduleRules")
    ),
    prevLink: "/admin/provider-profile",
  },

  Admin_EditProviderScheduleRules: {
    url: "/admin/edit-schedule-rules",
    componentTitle: "Provider Profiles",
    component: lazy(
      () =>
        import("../Pages/Admin/ProviderScheduleRules/EditProviderScheduleRules")
    ),
    prevLink: "/admin/provider-profile",
  },

  //Admin-Provider Contact History routes
  Admin_ProviderContactHistory: {
    url: "/admin/contact-history",
    componentTitle: "Provider Profiles",
    component: lazy(
      () => import("../Pages/Admin/ContactHistory/ContactHistory")
    ),
    prevLink: "/admin/provider-profile",
  },

  //Admin-Provider Profile routes
  Admin_ProviderProfile: {
    url: "/admin/provider-profile",
    componentTitle: "Admin Home",
    component: lazy(
      () => import("../Pages/Admin/ProviderProfile/ProviderProfile")
    ),
    prevLink: "/admin/homelisting",
  },

  Admin_EditProviderProfile: {
    url: "/admin/edit-provider-profile",
    componentTitle: "Provider Profiles",
    component: lazy(
      () => import("../Pages/Admin/ProviderProfile/EditProviderProfile")
    ),
    prevLink: "/admin/provider-profile",
  },

  Admin_AddPatient_contact: {
    url: "/admin/edit-contact-history",
    componentTitle: "Patient Profiles",
    component: lazy(
      () => import("../Pages/Admin/PatientProfile/PatientContactHistory")
    ),
    prevLink: "/admin/patient-profile",
  },
  // patient Appointment
  Admin_Appointment: {
    url: "/admin/appointments",
    componentTitle: "Admin Home",
    component: lazy(
      () =>
        import(
          "../Pages/Admin/PatientAppointments/Appointments/ProviderAppointment"
        )
    ),
    prevLink: "/admin/homelisting",
  },
  Admin_Patient_Appointment: {
    url: "/admin/patient-appointment",
    componentTitle: "Admin Home",
    component: lazy(
      () =>
        import(
          "../Pages/Admin/PatientAppointments/Appointments/PatientAppointment"
        )
    ),
    prevLink: "/admin/homelisting",
  },
  Admin_Patient_Cancel_Appointment: {
    url: "/admin/patient-cancel-appointment",
    component: lazy(
      () =>
        import(
          "../Pages/Admin/PatientAppointments/Cancelappointment/CancelAppointment"
        )
    ),
  },
  Admin_Patient_View_Appointment: {
    url: "/admin/view-appointment",
    componentTitle: "",
    component: lazy(
      () =>
        import(
          "../Pages/Admin/PatientAppointments/ViewAppointment/ViewAppointment"
        )
    ),
  },
};

export default routeHandlers;
