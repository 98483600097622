import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// utils components
import ImageHandler from "../../../Common/ImageHandler";
import {
  SideBarNavigationMenuOptionsList,
  SideBarNavigationButtonsList,
} from "../../Utils/ListingHandlers/SideBar/Sidebar";
import { useDispatch } from "react-redux";
import { resetState } from "../../../../Store/Slicers/userProfileSlicer";

const Navbar = ({ themeColor }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const currentMainPathLocation = location.pathname.slice(1).split("/")[0];
  const currentSubPathLocation = location.pathname.slice(1).split("/")[1];

  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    
    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, [location, navigate]);
  const dispatch = useDispatch()
  
  const handleReset = async ()=>{
   await  dispatch(resetState())
  }

  return (
    <div className="side-navbar p-1">
      <h2 className="heading pt-2 pb-3">my Medical Records</h2>
      <div className="medical-record-option">
      <ul className="navbar-nav justify-content-end flex-grow-1">
        {SideBarNavigationMenuOptionsList &&
          SideBarNavigationMenuOptionsList.map(
            (dataDict: any, dataDictIndex: any) => {
              return (
                <li
                  className="nav-item patient-nav-item dropdown"
                  key={`dropdown_item_navbar_${dataDictIndex}`}
                >
                  <a
                    className={
                      dataDict.urlPathName === currentMainPathLocation
                        ? "nav-link dropdown-toggle active"
                        : "nav-link dropdown-toggle"
                    }
                    href="/home"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <ImageHandler
                      theme={themeColor}
                      imageName={dataDict.iconImgName}
                      className="nav-img patient-nav-img"
                    />
                    <span>{dataDict.name}</span>
                  </a>
                  <ul
                    className={
                      currentMainPathLocation === dataDict.urlPathName
                        ? "dropdown-menu show"
                        : "dropdown-menu"
                    }
                  >
                    {dataDict.dropDownOptions &&
                      dataDict.dropDownOptions.map(
                        (dropDownItem: any, dropDownItemIndex: any) => {
                          return (
                            <div key={dropDownItemIndex}>
                              {deviceWidth > 767 ? (
                                <div
                                  key={`dropdown_option_${dropDownItemIndex}`}
                                >
                                  <li>
                                    <Link
                                      className={
                                        dropDownItem.urlPathName ===
                                        currentSubPathLocation
                                          ? "dropdown-item active"
                                          : "dropdown-item"
                                      }
                                      to={dropDownItem.url}
                                      onClick={handleReset}
                                    >
                                      {dropDownItem.name}
                                    </Link>
                                  </li>
                                  <li>
                                    <hr className="dropdown-divider" />
                                  </li>
                                </div>
                              ) : (
                                <div
                                  key={`dropdown_option_${dropDownItemIndex}`}
                                  data-bs-dismiss="offcanvas"
                                >
                                  <li>
                                    <Link
                                      className={
                                        dropDownItem.urlPathName ===
                                        currentSubPathLocation
                                          ? "dropdown-item active"
                                          : "dropdown-item"
                                      }
                                      to={dropDownItem.url}
                                      onClick={handleReset}
                                    >
                                      {dropDownItem.name}
                                    </Link>
                                  </li>
                                  <li>
                                    <hr className="dropdown-divider" />
                                  </li>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                  </ul>
                </li>
              );
            }
          )}
        {SideBarNavigationButtonsList &&
          SideBarNavigationButtonsList.map(
            (dataDict: any, dataDictIndex: any) => {
              return (
                <li
                  className="nav-item"
                  key={`dropdown_button_${dataDictIndex}`}
                >
                  <a className="nav-link" href={dataDict.url} role="button">
                    <ImageHandler
                      theme={themeColor}
                      imageName={dataDict.iconImgName}
                      className="nav-img"
                    />
                    <span>{dataDict.name}</span>
                  </a>
                </li>
              );
            }
          )}
      </ul>
      </div>
    </div>
  );
};

export default Navbar;
