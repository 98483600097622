// dev config
// export const AwsConfig = {
//   REGION: 'us-east-1',
//   USER_POOL_ID: 'us-east-1_tOk1YWWil',
//   USER_POOL_CLIENT_ID: '55l35o472uhqkil2sqg1a0acv9',
// };

// prod config
export const AwsConfig = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_LJEb634nj",
  USER_POOL_CLIENT_ID: "1njgsotg609vrjp5a254lc0k0s",
};
// export const AwsConfig = {
//   Auth: {
//     identityPoolId: "us-east-1:fddc4e90-7c6b-4728-95df-5e4022afc953",
//     region: "us-east-1",
//     userPoolId: "us-east-1_4vkn9A9yR",
//     userPoolWebClientId: "153nlvnb53kbuomscom88fuvue",
//   },
//   oauth: {
//     domain: "centiblickcare.auth.us-east-1.amazoncognito.com",
//     scope: ['email','openid'],
//     redirectSignIn: 'http://localhost:3000/provider-appointments',
//     redirectSignOut: 'http://localhost:3000/',
//     responseType: 'token'
//   }
// };
// const awsConfig = {
//   Auth: {
//       region: 'us-west-2', // Your Cognito Region
//       userPoolId: 'us-west-2_abc123', // Your Cognito User Pool ID
//       userPoolWebClientId: '2klmno567pqrs9tuv8wx', // App client id in User Pool
//       identityPoolId: 'us-west-2:xyz-123-uuid-4567', // Your Identity Pool ID
//       oauth: {
//           domain: 'your-cognito-domain.auth.us-west-2.amazoncognito.com',
//           scope: ['email', 'openid', 'profile'],
//           redirectSignIn: 'http://localhost:3000/',
//           redirectSignOut: 'http://localhost:3000/',
//           responseType: 'code'  // This is crucial for secure token handling
//       }
//   }
// };