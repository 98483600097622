import React from 'react'

//css
import './MedicalRecord.scss'
//component
import {  useNavigate } from "react-router-dom";
import Header from '../../../../Components/Patient/Core/Header/header'
import ImageHandler from '../../../../Components/Common/ImageHandler'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../Store'
import images from '../../../../Assets/Images'

const MedicalRecord = () => {
    const navigate = useNavigate();
    const { themeColor } = useSelector((state: RootState) => state.app);
  return (
    <div className='medical-record'>
        <Header/>
        <div>
        <div className='medical-record-details'>
            <div className='d-flex flex-column align-items-center justify-content-center medical-record-div mt-5'>
                <p className='text-center'>
                Your provider will be with you shortly. <br />
                While waiting please share your medical records.
                </p>
                <div className='d-flex align-items-center justify-content-center medical-record-main'>
                    <div className='d-flex flex-column align-items-center medical-record-left'>
                    <ImageHandler
                        theme={themeColor}
                        imageName="sidebarLogo"
                        className="pro-logo"
                      />
                      <h1>
                      Fri Aug 27 | 09:30 AM
                      </h1>
                      <img src={images.timeHeaderImg} alt="provider" className='provider-img'/>
                      <div className='text-center'>
                      <h1>Dr. Kelly Warren</h1>
                      <p>MD, PhD</p>
                      </div>
                    </div>
                    <div className='d-flex align-items-center medical-record-right'>
                        <img src={images.providerMarie} alt="img" />
                    </div>
                </div>
                <div className='d-flex justify-content-center gap-2'>
                <button onClick={() =>  navigate('/my-virtual-care/home')} className='virtual-care-button'>
                    Virtual Care Home
                    </button>
                    <button onClick={() =>  navigate('/my-virtual-care/video-chat')} className='share-medical-button'>
                    Share my Medical Records
                    </button>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default MedicalRecord