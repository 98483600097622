import { Auth } from "aws-amplify";
import moment from "moment";
import { store } from "../Store";
import PatientProfileSlice from "../Store/Slicers/ProfileInformation/patientProfileSlice";
import AdminProfileSlice from "../Store/Slicers/ProfileInformation/adminProfileSlice";
import ProviderProfileSlice from "../Store/Slicers/ProfileInformation/providerProfileSlice";
import UserMessageSlice from "../Store/Slicers/Messages/userMessageSlicer";
import * as momentTZ from "moment-timezone";
import html2pdf from 'html2pdf.js'
import { btoa } from "buffer";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

export function formatDate(stringDate: string) {
  const date = new Date(stringDate);
  const formattedDate = date.toLocaleString("en-us", {
    dateStyle: "medium",
  });
  return formattedDate;
}

export async function logoutHandler() {
  try {
    await Auth.signOut();
    localStorage.clear();
    store.dispatch(PatientProfileSlice.actions.resetState());
    store.dispatch(AdminProfileSlice.actions.resetState());
    store.dispatch(ProviderProfileSlice.actions.resetState());
    store.dispatch(UserMessageSlice.actions.resetState());
    window.location.assign("/");
  } catch (error) {
    console.error("Sign-out error:", error);
  }
}

export const GenderOption = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
]
export const USAStates = [
  { state: "Alabama", code: "AL" },
  { state: "Alaska", code: "AK" },
  { state: "Arizona", code: "AZ" },
  { state: "Arkansas", code: "AR" },
  { state: "California", code: "CA" },
  { state: "Colorado", code: "CO" },
  { state: "Connecticut", code: "CT" },
  { state: "Delaware", code: "DE" },
  { state: "District of Columbia", code: "DC" },
  { state: "Florida", code: "FL" },
  { state: "Georgia", code: "GA" },
  { state: "Hawaii", code: "HI" },
  { state: "Idaho", code: "ID" },
  { state: "Illinois", code: "IL" },
  { state: "Indiana", code: "IN" },
  { state: "Iowa", code: "IA" },
  { state: "Kansas", code: "KS" },
  { state: "Kentucky", code: "KY" },
  { state: "Louisiana", code: "LA" },
  { state: "Maine", code: "ME" },
  { state: "Maryland", code: "MD" },
  { state: "Massachusetts", code: "MA" },
  { state: "Michigan", code: "MI" },
  { state: "Minnesota", code: "MN" },
  { state: "Mississippi", code: "MS" },
  { state: "Missouri", code: "MO" },
  { state: "Montana", code: "MT" },
  { state: "Nebraska", code: "NE" },
  { state: "Nevada", code: "NV" },
  { state: "New Hampshire", code: "NH" },
  { state: "New Jersey", code: "NJ" },
  { state: "New Mexico", code: "NM" },
  { state: "New York", code: "NY" },
  { state: "North Carolina", code: "NC" },
  { state: "North Dakota", code: "ND" },
  { state: "Ohio", code: "OH" },
  { state: "Oklahoma", code: "OK" },
  { state: "Oregon", code: "OR" },
  { state: "Pennsylvania", code: "PA" },
  { state: "Rhode Island", code: "RI" },
  { state: "South Carolina", code: "SC" },
  { state: "South Dakota", code: "SD" },
  { state: "Tennessee", code: "TN" },
  { state: "Texas", code: "TX" },
  { state: "Utah", code: "UT" },
  { state: "Vermont", code: "VT" },
  { state: "Virginia", code: "VA" },
  { state: "Washington", code: "WA" },
  { state: "West Virginia", code: "WV" },
  { state: "Wisconsin", code: "WI" },
  { state: "Wyoming", code: "WY" },
];
export const USAStatesCB = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }]

export const getParameter = (key: string) => {
  const address = window.location.search;
  const parameterList = new URLSearchParams(address);
  return parameterList.get(key);
};

export const capitalize = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const getMonthStartAndEndDates = (date: string) => {
  const inputMoment = moment(date);
  const monthStartDate = inputMoment.startOf("month").toISOString();
  const monthEndDate = inputMoment.endOf("month").toISOString();
  return {
    start: monthStartDate,
    end: monthEndDate,
  };
};

export const convertDateFormatAppoinment = (inputDateStr: string) => {
  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateObject: Date = new Date(inputDateStr);

  const day: number = dateObject.getDate();
  const month: string = months[dateObject.getMonth()];
  const year: number = dateObject.getFullYear();

  return `${day} ${month} ${year}`;
};

// format date into from "2021-02-21T18:08:22"" to this format "10:00 AM Fri 27, Aug 202"
export function formatDateTime(inputDateTimeString: string) {
  const dateObject = new Date(inputDateTimeString);

  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
  const formattedMinutes = minutes.toString().padStart(2, "0");

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[dateObject.getDay()];

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[dateObject.getMonth()];

  const dayOfMonth = dateObject.getDate();
  const year = dateObject.getFullYear();

  const formattedDateTime = `${formattedHours}:${formattedMinutes} ${amPm} ${dayOfWeek} ${dayOfMonth}, ${monthName} ${year}`;

  return formattedDateTime;
}

// convert date from "2021-02-21T18:08:22" this to "02/21/2021" format
export function formatDateToMMDDYYYY(inputDateString: string) {
  const dateObject = new Date(inputDateString);

  const day = String(dateObject.getDate()).padStart(2, "0");
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const year = dateObject.getFullYear();

  return `${month}/${day}/${year}`;
}

export function formatDateToMMMDDYYYY(inputDateString: string) {
  const dateObject = new Date(inputDateString);

  const options: {} = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = dateObject.toLocaleDateString("en-US", options);

  return formattedDate;
}

export function capitalizeEachWord(inputString: string) {
  return inputString?.replace(/\b\w/g, (match) => match?.toUpperCase());
}

export const lightenColor = (hexColor: any, factor: any) => {
  const adjustedFactor = Math.max(0, Math.min(1, factor));

  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate the lightened color
  const newR = Math.round(r + (255 - r) * adjustedFactor);
  const newG = Math.round(g + (255 - g) * adjustedFactor);
  const newB = Math.round(b + (255 - b) * adjustedFactor);

  // Convert the new RGB values back to hex
  const newHexColor = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return newHexColor;
};

export const convertImageToBase64 = (image: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };

    reader.onerror = reject;

    reader.readAsDataURL(image);
  });
};

export function convertUtcToLocalTimeString(timeRange: string): string {
  const options: any = {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  };

  // Split the input time range string into start and end parts
  const [startStr, endStr] = timeRange?.split("-");

  // Parse the start and end strings into Date objects
  const startDate = new Date(Date.parse(startStr));
  const endDate = new Date(Date.parse(endStr));

  // Convert UTC to local time
  const localStartTime = startDate.toLocaleString("en-US", options);
  const localEndTime = endDate.toLocaleString("en-US", options);

  return `${localStartTime} - ${localEndTime}`;
}

//! function to convert utc to any local time

export const getFormattedLocalTime = (utcDateTime: any) => {
  const dateTime = moment.utc(utcDateTime).format("YYYY-MM-DD HH:mm:ss");

  //get text from divUTC and conver to local timezone
  var localTime: any = moment.utc(dateTime).toDate();
  localTime = moment(localTime).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z");
  return localTime;
};

//! end ut convert time

//! convert date into calender format
export const getDateConvert = (date: string) => {
  const dateTime = moment.utc(date).format("YYYY-MM-DD HH:mm:ss");

  //get text from divUTC and conver to local timezone
  var localTime: any = moment.utc(dateTime).toDate();
  localTime = moment(localTime).format("ddd MMM DD YYYY HH:mm:ss [GMT]Z");

  // Format the date in the desired format
  const parsedDate = moment(localTime, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");

  // Format the date in the desired format
  const formattedDate = parsedDate.format("YYYY-MM-DD");
  return formattedDate;
};

function updateTimeString(originalString: string) {
  const originalMoment = moment(
    originalString,
    "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)",
    "en"
  );

  const formattedString = originalMoment.format("YYYY-MM-DDTHH:mm:ss");
  return formattedString;
}

export function convertUtcToLocalTimeStringTimeZone(utcTimeString: any): any {
  if (!utcTimeString) {
    // Handle the case where utcTimeString is undefined or falsy
    return "Invalid UTC time string";
  }

  const [startStr, endStr, thr] = utcTimeString.split("-");

  let endDateSplit;
  if (thr === undefined) {
    endDateSplit = endStr;
  } else {
    endDateSplit = thr;
  }
  if (!startStr || !endStr) {
    // Handle the case where startStr or endStr is undefined or falsy
    return "Invalid start or end time string";
  }
  const d1 = updateTimeString(startStr);
  const d2 = updateTimeString(endDateSplit);
  const time1 = new Date(`${d1}`);
  const time2 = new Date(`${d2}`);

  // Convert UTC to local time
  const options: Intl.DateTimeFormatOptions = {
    hour12: true,
    hour: "numeric",
    minute: "2-digit",
  };

  // Format local start and end times
  const localStartTime = time1.toLocaleTimeString("en-US", options);
  const localEndTime = time2.toLocaleTimeString("en-US", options);

  return `${localStartTime} - ${localEndTime}`;
}

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function GetStartEndDayView(passedDate: Date) {
  const dateObject = new Date(passedDate!);
  dateObject.setHours(0, 0, 0, 0);

  // Create a new Date object representing the end of the day (23:59:59) of the given date
  const endOfDay = new Date(dateObject);
  endOfDay.setHours(23, 59, 59, 999);

  // Convert start and end timestamps to UTC timezone format
  const startUTC = !isNaN(dateObject.getTime()) ? dateObject.toISOString() : '';
  const endUTC = !isNaN(endOfDay.getTime()) ? endOfDay.toISOString() : '';
  return { startUTC, endUTC }
}

//! function takes string and give local yyyy-mm-dd formate of date
export function covnertLocal(dateTime: string) {
  // Convert UTC datetime to local timezone of the system
  const localTime = moment.utc(dateTime).tz(momentTZ.tz.guess());

  // Format local time as 'YYYY-MM-DD'
  const localDateFormatted = localTime.format('YYYY-MM-DD');

  return localDateFormatted;
}


//!takes a local date and time, converts it to UTC, and returns the string representation of the UTC date and time.
export function convertToUTCDateString(inputDateString: Date) {

  if (!inputDateString || isNaN(new Date(inputDateString).getTime())) {
    return "Invalid input date";
  }

  const localDate = new Date(inputDateString);
  // Get the UTC time in milliseconds
  const utcTime = localDate?.getTime() - localDate?.getTimezoneOffset() * 60000;
  // Create a new Date object with the UTC time
  const utcDate = new Date(utcTime);
  // Format the UTC date string
  const utcDateString = utcDate?.toISOString();
  return utcDateString;
}

//! takes a date and time in UTC, converts it to the local timezone, and returns the string representation of the local date and time.

export function localDayViewconvert(daydate: string) {
  const utcTime = moment.utc(daydate);

  const localTime = utcTime.local();

  return localTime.format()
}


//! takes this "2024-02-09" and return "Thu 09, Feb" format 

export function formateDay(inputDate: string): string {
  const date: Date = new Date(inputDate);
  const weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const weekdayIndex: number = date.getDay();
  const monthIndex: number = date.getMonth();
  const formattedDate: string = weekdays[weekdayIndex] + ' ' + (date.getDate() < 10 ? '0' : '') + date.getDate() + ', ' + months[monthIndex];
  return formattedDate;
}

// convert only time to utc like 12:00 to utc in same format
export function convertUTCTimeToLocalTime(time: string): string {
  // Parse the time using Moment.js and specify it's in UTC
  let utcTime = moment.utc(time, 'hh:mm A');

  // Convert the UTC time to the local timezone
  let localTime = utcTime.local();

  // Return the local time in the same format
  return localTime.format('hh:mm A');
}

//! Page print page functionallity

export const handlePrint = (id: string, dob: any, title: string, name?: string, e?: any) => {
  e?.preventDefault();
  e?.stopPropagation();

  const contentElement = document.getElementById(id);

  if (!contentElement) {
    console.error('Content element not found');
    return; // Exit the function if content element is not found
  }

  const contentToPrint = contentElement.innerHTML;

  // Create a temporary hidden iframe
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  // Create HTML content for the iframe
  const htmlContent = `
    <html>
      <head>
        <title>${title}</title>
        <style>
                    @media print {
                      h1 {
                        color: black;
                        text-align: center;
                      }
                    }
                  </style>
      </head>
      <body>
      <h1>${title}</h1>
      <h2 style="color: black; text-align: center; font-size: 20px; padding-bottom : 5px;">${name}</h2>
      <h2 style="color: black; text-align: center; font-size: 16px; padding-bottom : 10px;">${dob}</h2>
        ${contentToPrint}

      </body>
    </html>
  `;

  // Set iframe's document content using DOM manipulation
  const doc = iframe.contentWindow?.document;
  if (!doc) {
    console.error('Failed to access iframe document');
    document.body.removeChild(iframe); // Remove the iframe from the document
    return; // Exit the function if iframe document cannot be accessed
  }

  try {
    doc.open();
    doc.write(htmlContent);
    doc.close();

    // Print the iframe content
    iframe.contentWindow?.print();

    // Remove the iframe from the document after printing is complete
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000); // Delayed removal to ensure printing is completed
  } catch (error) {
    console.error('Error occurred while printing:', error);
    document.body.removeChild(iframe); // Remove the iframe from the document
  }
};
//! save as pdf functionallity

export const saveAsPDF = (id: string, dob: any, title: string, name?: string, e?: any) => {
  e?.preventDefault();
  e?.stopPropagation();
  const content = document.getElementById(id)?.innerHTML;
  const pdfContent = ` <html>
  <head>     
  <title>${title}</title>
  <style>
  .row {
      padding: 10px;
  }
  span {
      font-weight: 600; 
  }
  </style>
  </head>
  <body>
  <h1 style="color: black; text-align: center; font-size: 32px; padding-bottom : 10px;">${title}</h1>
  <h2 style="color: black; text-align: center; font-size: 20px; padding-bottom : 5px;">${name}</h2>
  <h2 style="color: black; text-align: center; font-size: 16px; padding-bottom : 10px;">${dob}</h2>
    ${content}
  </body>
</html>`

  const options = {
    margin: 10,
    filename: `${title}.pdf`,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 3 },
    pagebreak: { mode: "avoid-all" },
    jsPDF: { unit: 'mm', format: 'a4' },
  }

  html2pdf(pdfContent, options);
};

export const savePDFAsFormData = (id: string, title: string, name?: string, dataUrl?: string | null) => {
  return new Promise((resolve, reject) => {
    const content = document.getElementById(id)?.innerHTML;
    const pdfContent = `<html>
        <head>     
          <title>${title}</title>
          </head>
          <body>
          <h1 style="color: black; text-align: center; font-size: 32px; padding-bottom : 10px;">${title}</h1>
          <h2 style="color: black; text-align: center; font-size: 20px; padding-bottom : 10px;">${name}</h2>
          ${content}
        </body>
      </html>`;

    const options = {
      margin: 10,
      filename: `${title}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 3 },
      pagebreak: { before: ".page-break", after: "1cm" },
      jsPDF: { unit: 'mm', format: 'a4' },
    };

    // Generate PDF using html2pdf.js
    // console.log('Generating PDF...');
    //how to generate base64 from pdf store in db in react using  html2pdf().from(pdfContent).set(options).output().then((pdfBlob: any) this?


    // var pdf = new jsPDF();
    //   html2pdf().set(options).from(pdfContent).toPdf()
    html2pdf()
      .set(options)
      .from(pdfContent)
      .toPdf()
      .output('datauristring')
      .then((pdfBlob: any) => {

        // const reader = new FileReader();
        // reader.onloadend = () => {
        // console.log('reader :', reader.result);
        // formData.append('patient_info_form_base_64_string', pdfBlob, `${title}.pdf`);
        //   // const base64String:any = reader.result as string;
        //   // resolve(base64String.split(",")[1]);
        // };
        // reader.readAsDataURL(pdfBlob);
        resolve(pdfBlob)
      })
      .catch((error: any) => {
        console.error('Error generating PDF:', error);
        // If there's an error, reject the promise
        reject(error);
      });
  });
}


export const formatDateRange = (fromDateStr: string, toDateStr: string): string | null => {

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const fromDate = new Date(fromDateStr);
  const toDate = new Date(toDateStr);

  if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
    throw new Error('Invalid date format');
  }

  // const formattedDate = fromDate.toLocaleString('en-US', dateOptions);
  const formattedFromTime = fromDate.toLocaleString('en-US', timeOptions);
  const formattedToTime = toDate.toLocaleString('en-US', timeOptions);

  return `${formattedFromTime} to ${formattedToTime}`;
};

export function getDateAfterThreeMonths(dateString: string) {
  const currentDate = new Date(dateString);
  currentDate.setMonth(currentDate.getMonth() + 3);
  return currentDate.toISOString();
}

export function getDateAfterOneMonths(dateString: string) {
  const currentDate = new Date(dateString);
  currentDate.setMonth(currentDate.getMonth() + 1);
  return currentDate.toISOString();
}

export function convertToLocalDateTimeToUTC(localDateTimeString: any) {
  const localTimezone = moment.tz.guess();
  const localDateTime = moment.tz(localDateTimeString, localTimezone);
  const utcDateTime = localDateTime.utc();
  const formattedUtcDateTime = utcDateTime.format();
  return formattedUtcDateTime;
}
export function convertFromUTCToLocalDateTime(utcDateTimeString: any) {
  const localTimezone = moment.tz.guess();
  const utcDateTime = moment.utc(utcDateTimeString);
  const localDateTime = utcDateTime.clone().tz(localTimezone);
  const formattedLocalDateTime = localDateTime.format('YYYY-MM-DDTHH:mm:ss');
  return formattedLocalDateTime;
}

export const copyLinkToClipboard = (Link: any) => {
  const link = Link;
  const textArea = document.createElement('textarea');
  textArea.value = link;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
  toast.success("Link Copy successfully")
};

export const getInitials = (str: string) => {
  const words = str?.split(' ');

  const capitalizedWords = words?.map((word: string) => word.charAt(0).toUpperCase());

  return capitalizedWords?.join('');

};

export const formatPhoneNumber = (value: string) => {
  // Remove all non-numeric characters from the input value
  const phoneNumber = value.replace(/\D/g, '');

  // If the input value has more than 10 digits, truncate it to 10 digits
  const truncatedPhoneNumber = phoneNumber.slice(0, 10);

  // Format the truncated phone number as (XXX) XXX-XXXX
  const formattedPhoneNumber = truncatedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  return formattedPhoneNumber;
};

export const formatSocialSecurityNumber = (value: string) => {
  // Remove all non-numeric characters from the input value
  const socialSecurityNumber = value.replace(/\D/g, '');

  // If the input value has more than 10 digits, truncate it to 10 digits
  const truncatedSocialSecurityNumber = socialSecurityNumber.slice(0, 9);

  // Format the truncated phone number as (XXX) XXX-XXXX
  const formattedSocialSecurityNumber = truncatedSocialSecurityNumber.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');

  return formattedSocialSecurityNumber;
};

export const Specialty = [
  { value: "Allergy & Immunology Physician", label: "Allergy & Immunology Physician" },
  { value: "Allergy & Immunology - Allergy Physician", label: "Allergy & Immunology - Allergy Physician" },
  { value: "Allergy & Immunology - Clinical & Laboratory Immunology Physician", label: "Allergy & Immunology - Clinical & Laboratory Immunology Physician" },
  { value: "Anesthesiology Physician", label: "Anesthesiology Physician" },
  { value: "Anesthesiology - Addiction Medicine Physician", label: "Anesthesiology - Addiction Medicine Physician" },
  { value: "Anesthesiology - Critical Care Medicine Physician", label: "Anesthesiology - Critical Care Medicine Physician" },
  { value: "Anesthesiology - Hospice & Palliative Medicine Physician", label: "Anesthesiology - Hospice & Palliative Medicine Physician" },
  { value: "Anesthesiology - Pain Medicine Physician", label: "Anesthesiology - Pain Medicine Physician" },
  { value: "Anesthesiology - Pediatric Anesthesiology Physician", label: "Anesthesiology - Pediatric Anesthesiology Physician" },
  { value: "Clinical Pharmacology Physician", label: "Clinical Pharmacology Physician" },
  { value: "Colon & Rectal Surgery Physician", label: "Colon & Rectal Surgery Physician" },
  { value: "Dermatology Physician", label: "Dermatology Physician" },
  { value: "Dermatology - Clinical & Laboratory Dermatological Immunology Physician", label: "Dermatology - Clinical & Laboratory Dermatological Immunology Physician" },
  { value: "Dermatology - Dermatopathology Physician", label: "Dermatology - Dermatopathology Physician" },
  { value: "Dermatology - MOHS-Micrographic Surgery Physician", label: "Dermatology - MOHS-Micrographic Surgery Physician" },
  { value: "Dermatology - Pediatric Dermatology Physician", label: "Dermatology - Pediatric Dermatology Physician" },
  { value: "Dermatology - Procedural Dermatology Physician", label: "Dermatology - Procedural Dermatology Physician" },
  { value: "Electrodiagnostic Medicine Physician", label: "Electrodiagnostic Medicine Physician" },
  { value: "Emergency Medicine Physician", label: "Emergency Medicine Physician" },
  { value: "Emergency Medicine - Emergency Medical Services Physician", label: "Emergency Medicine - Emergency Medical Services Physician" },
  { value: "Emergency Medicine - Hospice & Palliative Medicine Physician", label: "Emergency Medicine - Hospice & Palliative Medicine Physician" },
  { value: "Emergency Medicine - Medical Toxicology Physician", label: "Emergency Medicine - Medical Toxicology Physician" },
  { value: "Emergency Medicine - Pediatric Emergency Medicine Physician", label: "Emergency Medicine - Pediatric Emergency Medicine Physician" },
  { value: "Emergency Medicine - Sports Medicine Physician", label: "Emergency Medicine - Sports Medicine Physician" },
  { value: "Emergency Medicine - Undersea & Hyperbaric Medicine Physician", label: "Emergency Medicine - Undersea & Hyperbaric Medicine Physician" },
  { value: "Family Medicine Physician", label: "Family Medicine Physician" },
  { value: "Family Medicine - Addiction Medicine Physician", label: "Family Medicine - Addiction Medicine Physician" },
  { value: "Family Medicine - Adolescent Medicine Physician", label: "Family Medicine - Adolescent Medicine Physician" },
  { value: "Family Medicine - Adult Medicine Physician", label: "Family Medicine - Adult Medicine Physician" },
  { value: "Family Medicine - Geriatric Medicine Physician", label: "Family Medicine - Geriatric Medicine Physician" },
  { value: "Family Medicine - Hospice & Palliative Medicine Physician", label: "Family Medicine - Hospice & Palliative Medicine Physician" },
  { value: "Family Medicine - Obesity Medicine Physician", label: "Family Medicine - Obesity Medicine Physician" },
  { value: "Family Medicine - Sleep Medicine Physician", label: "Family Medicine - Sleep Medicine Physician" },
  { value: "Family Medicine - Sports Medicine Physician", label: "Family Medicine - Sports Medicine Physician" },
  { value: "General Practice Physician", label: "General Practice Physician" },
  { value: "Hospitalist Physician", label: "Hospitalist Physician" },
  { value: "Independent Medical Examiner Physician", label: "Independent Medical Examiner Physician" },
  { value: "Integrative Medicine Physician ", label: "Integrative Medicine Physician " },
  { value: "Internal Medicine Physician", label: "Internal Medicine Physician" },
  { value: "Internal Medicine - Addiction Medicine Physician", label: "Internal Medicine - Addiction Medicine Physician" },
  { value: "Internal Medicine - Adolescent Medicine Physician", label: "Internal Medicine - Adolescent Medicine Physician" },
  { value: "Internal Medicine - Adult Congenital Heart Disease Physician", label: "Internal Medicine - Adult Congenital Heart Disease Physician" },
  { value: "Internal Medicine - Advanced Heart Failure & Transplant Cardiology Physician", label: "Internal Medicine - Advanced Heart Failure & Transplant Cardiology Physician" },
  { value: "Internal Medicine - Allergy & Immunology Physician", label: "Internal Medicine - Allergy & Immunology Physician" },
  { value: "Internal Medicine - Cardiovascular Disease Physician", label: "Internal Medicine - Cardiovascular Disease Physician" },
  { value: "Internal Medicine - Clinical & Laboratory Immunology Physician", label: "Internal Medicine - Clinical & Laboratory Immunology Physician" },
  { value: "Internal Medicine - Clinical Cardiac Electrophysiology Physician", label: "Internal Medicine - Clinical Cardiac Electrophysiology Physician" },
  { value: "Internal Medicine - Critical Care Medicine Physician", label: "Internal Medicine - Critical Care Medicine Physician" },
  { value: "Internal Medicine - Endocrinology, Diabetes & Metabolism Physician", label: "Internal Medicine - Endocrinology, Diabetes & Metabolism Physician" },
  { value: "Internal Medicine - Gastroenterology Physician", label: "Internal Medicine - Gastroenterology Physician" },
  { value: "Internal Medicine - Geriatric Medicine Physician", label: "Internal Medicine - Geriatric Medicine Physician" },
  { value: "Internal Medicine - Hematology Physician", label: "Internal Medicine - Hematology Physician" },
  { value: "Internal Medicine - Hematology & Oncology Physician", label: "Internal Medicine - Hematology & Oncology Physician" },
  { value: "Internal Medicine - Hepatology Physician", label: "Internal Medicine - Hepatology Physician" },
  { value: "Internal Medicine - Hospice & Palliative Medicine Physician", label: "Internal Medicine - Hospice & Palliative Medicine Physician" },
  { value: "Internal Medicine - Hypertension Specialist Physician", label: "Internal Medicine - Hypertension Specialist Physician" },
  { value: "Internal Medicine - Infectious Disease Physician", label: "Internal Medicine - Infectious Disease Physician" },
  { value: "Internal Medicine - Interventional Cardiology Physician", label: "Internal Medicine - Interventional Cardiology Physician" },
  { value: "Internal Medicine - Magnetic Resonance Imaging (MRI) Physician", label: "Internal Medicine - Magnetic Resonance Imaging (MRI) Physician" },
  { value: "Internal Medicine - Medical Oncology Physician", label: "Internal Medicine - Medical Oncology Physician" },
  { value: "Internal Medicine - Nephrology Physician", label: "Internal Medicine - Nephrology Physician" },
  { value: "Internal Medicine - Obesity Medicine Physician", label: "Internal Medicine - Obesity Medicine Physician" },
  { value: "Internal Medicine - Pulmonary Disease Physician", label: "Internal Medicine - Pulmonary Disease Physician" },
  { value: "Internal Medicine - Rheumatology Physician", label: "Internal Medicine - Rheumatology Physician" },
  { value: "Internal Medicine - Sleep Medicine Physician", label: "Internal Medicine - Sleep Medicine Physician" },
  { value: "Internal Medicine - Sports Medicine Physician", label: "Internal Medicine - Sports Medicine Physician" },
  { value: "Internal Medicine - Transplant Hepatology Physician", label: "Internal Medicine - Transplant Hepatology Physician" },
  { value: "Legal Medicine (M.D./D.O.) Physician", label: "Legal Medicine (M.D./D.O.) Physician" },
  { value: "Medical Genetics Physician", label: "Medical Genetics Physician" },
  { value: "Medical Genetics - Clinical Biochemical Genetics Physician", label: "Medical Genetics - Clinical Biochemical Genetics Physician" },
  { value: "Medical Genetics - Clinical Cytogenetics Physician", label: "Medical Genetics - Clinical Cytogenetics Physician" },
  { value: "Medical Genetics - Clinical Genetics (M.D.) Physician", label: "Medical Genetics - Clinical Genetics (M.D.) Physician" },
  { value: "Medical Genetics - Clinical Molecular Genetics Physician", label: "Medical Genetics - Clinical Molecular Genetics Physician" },
  { value: "Medical Genetics - Medical Biochemical Physician", label: "Medical Genetics - Medical Biochemical Physician" },
  { value: "Medical Genetics - Molecular Genetic Pathology Physician", label: "Medical Genetics - Molecular Genetic Pathology Physician" },
  { value: "Medical Genetics - Ph.D. Medical Genetics Physician", label: "Medical Genetics - Ph.D. Medical Genetics Physician" },
  { value: "Neurological Surgery Physician", label: "Neurological Surgery Physician" },
  { value: "Neuromusculoskeletal Medicine & OMM Physician", label: "Neuromusculoskeletal Medicine & OMM Physician" },
  { value: "Neuromusculoskeletal Sports Medicine Physician", label: "Neuromusculoskeletal Sports Medicine Physician" },
  { value: "Nuclear Medicine Physician", label: "Nuclear Medicine Physician" },
  { value: "Nuclear Medicine - In Vivo & In Vitro Nuclear Medicine Physician", label: "Nuclear Medicine - In Vivo & In Vitro Nuclear Medicine Physician" },
  { value: "Nuclear Medicine - Nuclear Cardiology Physician", label: "Nuclear Medicine - Nuclear Cardiology Physician" },
  { value: "Nuclear Medicine - Nuclear Imaging & Therapy Physician", label: "Nuclear Medicine - Nuclear Imaging & Therapy Physician" },
  { value: "Obstetrics & Gynecology Physician", label: "Obstetrics & Gynecology Physician" },
  { value: "Obstetrics & Gynecology - Complex Family Planning Physician", label: "Obstetrics & Gynecology - Complex Family Planning Physician" },
  { value: "Obstetrics & Gynecology - Critical Care Medicine Physician", label: "Obstetrics & Gynecology - Critical Care Medicine Physician" },
  { value: "Obstetrics & Gynecology - Female Pelvic Medicine & Reconstructive Surgery Physician", label: "Obstetrics & Gynecology - Female Pelvic Medicine & Reconstructive Surgery Physician" },
  { value: "Obstetrics & Gynecology - Gynecologic Oncology Physician", label: "Obstetrics & Gynecology - Gynecologic Oncology Physician" },
  { value: "Obstetrics & Gynecology - Gynecology Physician", label: "Obstetrics & Gynecology - Gynecology Physician" },
  { value: "Obstetrics & Gynecology - Hospice & Palliative Medicine Physician", label: "Obstetrics & Gynecology - Hospice & Palliative Medicine Physician" },
  { value: "Obstetrics & Gynecology - Maternal & Fetal Medicine Physician", label: "Obstetrics & Gynecology - Maternal & Fetal Medicine Physician" },
  { value: "Obstetrics & Gynecology - Obesity Medicine Physician", label: "Obstetrics & Gynecology - Obesity Medicine Physician" },
  { value: "Obstetrics & Gynecology - Obstetrics Physician", label: "Obstetrics & Gynecology - Obstetrics Physician" },
  { value: "Obstetrics & Gynecology - Reproductive Endocrinology Physician", label: "Obstetrics & Gynecology - Reproductive Endocrinology Physician" },
  { value: "Ophthalmology Physician", label: "Ophthalmology Physician" },
  { value: "Ophthalmology - Cornea & External Diseases Specialist Physician", label: "Ophthalmology - Cornea & External Diseases Specialist Physician" },
  { value: "Ophthalmology - Glaucoma Specialist Physician", label: "Ophthalmology - Glaucoma Specialist Physician" },
  { value: "Ophthalmology - Neuro-ophthalmology Physician", label: "Ophthalmology - Neuro-ophthalmology Physician" },
  { value: "Ophthalmology - Ophthalmic Plastic & Reconstructive Surgery Physician", label: "Ophthalmology - Ophthalmic Plastic & Reconstructive Surgery Physician" },
  { value: "Ophthalmology - Pediatric Ophthalmology and Strabismus Specialist Physician", label: "Ophthalmology - Pediatric Ophthalmology and Strabismus Specialist Physician" },
  { value: "Ophthalmology - Retina Specialist Physician", label: "Ophthalmology - Retina Specialist Physician" },
  { value: "Ophthalmology - Uveitis & Ocular Inflammatory Disease Physician", label: "Ophthalmology - Uveitis & Ocular Inflammatory Disease Physician" },
  { value: "Oral & Maxillofacial Surgery (D.M.D.)", label: "Oral & Maxillofacial Surgery (D.M.D.)" },
  { value: "Orthopaedic Surgery Physician", label: "Orthopaedic Surgery Physician" },
  { value: "Orthopaedic Surgery - Adult Reconstructive Orthopaedic Surgery Physician", label: "Orthopaedic Surgery - Adult Reconstructive Orthopaedic Surgery Physician" },
  { value: "Orthopaedic Surgery - Orthopaedic Foot & Ankle Surgery Physician", label: "Orthopaedic Surgery - Orthopaedic Foot & Ankle Surgery Physician" },
  { value: "Orthopaedic Surgery - Orthopaedic Hand Surgery Physician", label: "Orthopaedic Surgery - Orthopaedic Hand Surgery Physician" },
  { value: "Orthopaedic Surgery - Orthopaedic Surgery of the Spine Physician", label: "Orthopaedic Surgery - Orthopaedic Surgery of the Spine Physician" },
  { value: "Orthopaedic Surgery - Orthopaedic Trauma Physician", label: "Orthopaedic Surgery - Orthopaedic Trauma Physician" },
  { value: "Orthopaedic Surgery - Pediatric Orthopaedic Surgery Physician", label: "Orthopaedic Surgery - Pediatric Orthopaedic Surgery Physician" },
  { value: "Orthopaedic Surgery - Sports Medicine Physician", label: "Orthopaedic Surgery - Sports Medicine Physician" },
  { value: "Otolaryngology Physician", label: "Otolaryngology Physician" },
  { value: "Otolaryngology - Facial Plastic Surgery Physician", label: "Otolaryngology - Facial Plastic Surgery Physician" },
  { value: "Otolaryngology - Otolaryngic Allergy Physician", label: "Otolaryngology - Otolaryngic Allergy Physician" },
  { value: "Otolaryngology - Otolaryngology/Facial Plastic Surgery Physician", label: "Otolaryngology - Otolaryngology/Facial Plastic Surgery Physician" },
  { value: "Otolaryngology - Otology & Neurotology Physician", label: "Otolaryngology - Otology & Neurotology Physician" },
  { value: "Otolaryngology - Pediatric Otolaryngology Physician", label: "Otolaryngology - Pediatric Otolaryngology Physician" },
  { value: "Otolaryngology - Plastic Surgery within the Head & Neck Physician", label: "Otolaryngology - Plastic Surgery within the Head & Neck Physician" },
  { value: "Otolaryngology - Sleep Medicine Physician", label: "Otolaryngology - Sleep Medicine Physician" },
  { value: "Pain Medicine - Interventional Pain Medicine Physician", label: "Pain Medicine - Interventional Pain Medicine Physician" },
  { value: "Pain Medicine - Pain Medicine Physician", label: "Pain Medicine - Pain Medicine Physician" },
  { value: "Pathology - Anatomic Pathology Physician", label: "Pathology - Anatomic Pathology Physician" },
  { value: "Pathology - Anatomic Pathology & Clinical Pathology Physician", label: "Pathology - Anatomic Pathology & Clinical Pathology Physician" },
  { value: "Pathology - Blood Banking & Transfusion Medicine Physician", label: "Pathology - Blood Banking & Transfusion Medicine Physician" },
  { value: "Pathology - Chemical Pathology Physician", label: "Pathology - Chemical Pathology Physician" },
  { value: "Pathology - Clinical Informatics Physician", label: "Pathology - Clinical Informatics Physician" },
  { value: "Pathology - Clinical Pathology Physician", label: "Pathology - Clinical Pathology Physician" },
  { value: "Pathology - Clinical Pathology/Laboratory Medicine Physician", label: "Pathology - Clinical Pathology/Laboratory Medicine Physician" },
  { value: "Pathology - Cytopathology Physician", label: "Pathology - Cytopathology Physician" },
  { value: "Pathology - Dermatopathology Physician", label: "Pathology - Dermatopathology Physician" },
  { value: "Pathology - Forensic Pathology Physician", label: "Pathology - Forensic Pathology Physician" },
  { value: "Pathology - Hematology Physician", label: "Pathology - Hematology Physician" },
  { value: "Pathology - Immunopathology Physician", label: "Pathology - Immunopathology Physician" },
  { value: "Pathology - Medical Microbiology Physician", label: "Pathology - Medical Microbiology Physician" },
  { value: "Pathology - Molecular Genetic Pathology Physician", label: "Pathology - Molecular Genetic Pathology Physician" },
  { value: "Pathology - Neuropathology Physician", label: "Pathology - Neuropathology Physician" },
  { value: "Pathology - Pediatric Pathology Physician", label: "Pathology - Pediatric Pathology Physician" },
  { value: "Pediatrics Physician", label: "Pediatrics Physician" },
  { value: "Pediatrics - Pediatric Adolescent Medicine Physician", label: "Pediatrics - Pediatric Adolescent Medicine Physician" },
  { value: "Pediatrics - Child Abuse Pediatrics Physician", label: "Pediatrics - Child Abuse Pediatrics Physician" },
  { value: "Pediatrics - Pediatric Clinical & Laboratory Immunology Physician", label: "Pediatrics - Pediatric Clinical & Laboratory Immunology Physician" },
  { value: "Pediatrics - Developmental - Behavioral Pediatrics Physician", label: "Pediatrics - Developmental - Behavioral Pediatrics Physician" },
  { value: "Pediatrics - Pediatric Hospice & Palliative Medicine Physician", label: "Pediatrics - Pediatric Hospice & Palliative Medicine Physician" },
  { value: "Pediatrics - Pediatric Medical Toxicology Physician", label: "Pediatrics - Pediatric Medical Toxicology Physician" },
  { value: "Pediatrics - Neonatal-Perinatal Medicine Physician", label: "Pediatrics - Neonatal-Perinatal Medicine Physician" },
  { value: "Pediatrics - Pediatric Neurodevelopmental Disabilities Physician", label: "Pediatrics - Pediatric Neurodevelopmental Disabilities Physician" },
  { value: "Pediatrics - Pediatric Obesity Medicine Physician", label: "Pediatrics - Pediatric Obesity Medicine Physician" },
  { value: "Pediatrics - Pediatric Allergy/Immunology Physician", label: "Pediatrics - Pediatric Allergy/Immunology Physician" },
  { value: "Pediatrics - Pediatric Cardiology Physician", label: "Pediatrics - Pediatric Cardiology Physician" },
  { value: "Pediatrics - Pediatric Critical Care Medicine Physician", label: "Pediatrics - Pediatric Critical Care Medicine Physician" },
  { value: "Pediatrics - Pediatric Emergency Medicine Physician", label: "Pediatrics - Pediatric Emergency Medicine Physician" },
  { value: "Pediatrics - Pediatric Endocrinology Physician", label: "Pediatrics - Pediatric Endocrinology Physician" },
  { value: "Pediatrics - Pediatric Gastroenterology Physician", label: "Pediatrics - Pediatric Gastroenterology Physician" },
  { value: "Pediatrics - Pediatric Hematology & Oncology Physician", label: "Pediatrics - Pediatric Hematology & Oncology Physician" },
  { value: "Pediatrics - Pediatric Infectious Diseases Physician", label: "Pediatrics - Pediatric Infectious Diseases Physician" },
  { value: "Pediatrics - Pediatric Nephrology Physician", label: "Pediatrics - Pediatric Nephrology Physician" },
  { value: "Pediatrics - Pediatric Pulmonology Physician", label: "Pediatrics - Pediatric Pulmonology Physician" },
  { value: "Pediatrics - Pediatric Rheumatology Physician", label: "Pediatrics - Pediatric Rheumatology Physician" },
  { value: "Pediatrics - Pediatric Transplant Hepatology Physician", label: "Pediatrics - Pediatric Transplant Hepatology Physician" },
  { value: "Pediatrics - Pediatric Sleep Medicine Physician", label: "Pediatrics - Pediatric Sleep Medicine Physician" },
  { value: "Pediatrics - Pediatric Sports Medicine Physician", label: "Pediatrics - Pediatric Sports Medicine Physician" },
  { value: "Phlebology Physician", label: "Phlebology Physician" },
  { value: "Physical Medicine & Rehabilitation Physician", label: "Physical Medicine & Rehabilitation Physician" },
  { value: "Physical Medicine & Rehabilitation - Brain Injury Medicine Physician", label: "Physical Medicine & Rehabilitation - Brain Injury Medicine Physician" },
  { value: "Physical Medicine & Rehabilitation - Hospice & Palliative Medicine Physician", label: "Physical Medicine & Rehabilitation - Hospice & Palliative Medicine Physician" },
  { value: "Physical Medicine & Rehabilitation - Neuromuscular Medicine Physician", label: "Physical Medicine & Rehabilitation - Neuromuscular Medicine Physician" },
  { value: "Physical Medicine & Rehabilitation - Pain Medicine Physician", label: "Physical Medicine & Rehabilitation - Pain Medicine Physician" },
  { value: "Physical Medicine & Rehabilitation - Pediatric Rehabilitation Medicine Physician", label: "Physical Medicine & Rehabilitation - Pediatric Rehabilitation Medicine Physician" },
  { value: "Physical Medicine & Rehabilitation - Spinal Cord Injury Medicine Physician", label: "Physical Medicine & Rehabilitation - Spinal Cord Injury Medicine Physician" },
  { value: "Physical Medicine & Rehabilitation - Sports Medicine Physician", label: "Physical Medicine & Rehabilitation - Sports Medicine Physician" },
  { value: "Plastic Surgery Physician", label: "Plastic Surgery Physician" },
  { value: "Plastic Surgery - Plastic Surgery within the Head & Neck Physician", label: "Plastic Surgery - Plastic Surgery within the Head & Neck Physician" },
  { value: "Plastic Surgery - Surgery of the Hand Physician", label: "Plastic Surgery - Surgery of the Hand Physician" },
  { value: "Preventative Medicine Physician", label: "Preventative Medicine Physician" },
  { value: "Preventative Medicine - Addiction Medicine Physician", label: "Preventative Medicine - Addiction Medicine Physician" },
  { value: "Preventative Medicine - Aerospace Medicine Physician", label: "Preventative Medicine - Aerospace Medicine Physician" },
  { value: "Preventative Medicine - Clinical Informatics Physician", label: "Preventative Medicine - Clinical Informatics Physician" },
  { value: "Preventative Medicine - Medical Toxicology Physician", label: "Preventative Medicine - Medical Toxicology Physician" },
  { value: "Preventative Medicine - Obesity Medicine Physician", label: "Preventative Medicine - Obesity Medicine Physician" },
  { value: "Preventative Medicine - Occupational Medicine Physician", label: "Preventative Medicine - Occupational Medicine Physician" },
  { value: "Preventative Medicine - Preventative Medicine/Occupational Environmental Medicine Physician", label: "Preventative Medicine - Preventative Medicine/Occupational Environmental Medicine Physician" },
  { value: "Preventative Medicine - Public Health & General Preventative Medicine Physician", label: "Preventative Medicine - Public Health & General Preventative Medicine Physician" },
  { value: "Preventative Medicine - Sports Medicine Physician", label: "Preventative Medicine - Sports Medicine Physician" },
  { value: "Preventative Medicine - Undersea & Hyperbaric Medicine Physician", label: "Preventative Medicine - Undersea & Hyperbaric Medicine Physician" },
  { value: "Radiology Physician", label: "Radiology Physician"},
  { value: "Psychiatry & Neurology Physician", label: "Psychiatry & Neurology Physician"},
  { value: "Psychiatry & Neurology - Addiction Medicine Physician", label: "Psychiatry & Neurology - Addiction Medicine Physician" },
  { value: "Psychiatry & Neurology - Addiction Psychiatry Physician", label: "Psychiatry & Neurology - Addiction Psychiatry Physician" },
  { value: "Psychiatry & Neurology - Behavioral Neurology & Neuropsychiatry Physician", label: "Psychiatry & Neurology - Behavioral Neurology & Neuropsychiatry Physician" },
  { value: "Psychiatry & Neurology - Brain Injury Medicine Physician", label: "Psychiatry & Neurology - Brain Injury Medicine Physician" },
  { value: "Psychiatry & Neurology - Child & Adolescent Psychiatry Physician", label: "Psychiatry & Neurology - Child & Adolescent Psychiatry Physician" },
  { value: "Psychiatry & Neurology - Clinical Neurophysiology Physician", label: "Psychiatry & Neurology - Clinical Neurophysiology Physician" },
  { value: "Psychiatry & Neurology - Diagnostic Neuroimaging Physician", label: "Psychiatry & Neurology - Diagnostic Neuroimaging Physician" },
  { value: "Psychiatry & Neurology - Epilepsy Physician", label: "Psychiatry & Neurology - Epilepsy Physician" },
  { value: "Psychiatry & Neurology - Forensic Psychiatry Physician", label: "Psychiatry & Neurology - Forensic Psychiatry Physician" },
  { value: "Psychiatry & Neurology - Geriatric Psychiatry Physician", label: "Psychiatry & Neurology - Geriatric Psychiatry Physician" },
  { value: "Psychiatry & Neurology - Hospice & Palliative Medicine Physician", label: "Psychiatry & Neurology - Hospice & Palliative Medicine Physician" },
  { value: "Psychiatry & Neurology - Neurocritical Physician", label: "Psychiatry & Neurology - Neurocritical Physician" },
  { value: "Psychiatry & Neurology - Neurodevelopmental Disabilities Physician", label: "Psychiatry & Neurology - Neurodevelopmental Disabilities Physician" },
  { value: "Psychiatry & Neurology - Neurology Physician", label: "Psychiatry & Neurology - Neurology Physician" },
  { value: "Psychiatry & Neurology - Neurology with Special Qualifications in Child Neurology Physician", label: "Psychiatry & Neurology - Neurology with Special Qualifications in Child Neurology Physician" },
  { value: "Psychiatry & Neurology -Neuromuscular Medicine Physician", label: "Psychiatry & Neurology -Neuromuscular Medicine Physician" },
  { value: "Psychiatry & Neurology - Obesity Medicine Physician", label: "Psychiatry & Neurology - Obesity Medicine Physician" },
  { value: "Psychiatry & Neurology - Pain Medicine Physician", label: "Psychiatry & Neurology - Pain Medicine Physician" },
  { value: "Psychiatry & Neurology - Psychiatry Physician", label: "Psychiatry & Neurology - Psychiatry Physician" },
  { value: "Psychiatry & Neurology - Psychosomatic Medicine Physician", label: "Psychiatry & Neurology - Psychosomatic Medicine Physician" },
  { value: "Psychiatry & Neurology - Sleep Medicine Physician", label: "Psychiatry & Neurology - Sleep Medicine Physician" },
  { value: "Psychiatry & Neurology - Sports Medicine Physician", label: "Psychiatry & Neurology - Sports Medicine Physician" },
  { value: "Psychiatry & Neurology - Vascular Neurology Physician", label: "Psychiatry & Neurology - Vascular Neurology Physician" },
  { value: "Registered Nurse - Addiction (Substance Abuse disorder) Registered Nurse", label: "Registered Nurse - Addiction (Substance Abuse disorder) Registered Nurse",},
  { value: "Registered Nurse - Administrator Registered Nurse", label: "Registered Nurse - Administrator Registered Nurse",},
  { value: "Registered Nurse - Ambulatory Care Registered Nurse", label: "Registered Nurse - Ambulatory Care Registered Nurse",},
  { value: "Registered Nurse - Cardiac Rehabilitation Registered Nurse", label: "Registered Nurse - Cardiac Rehabilitation Registered Nurse",},
  { value: "Registered Nurse - Case Managment Registered Nurse", label: "Registered Nurse - Case Managment Registered Nurse",},
  { value: "Registered Nurse - College Health Registered Nurse", label: "Registered Nurse - College Health Registered Nurse",},
  { value: "Registered Nurse - Community Health Registered Nurse", label: "Registered Nurse - Community Health Registered Nurse",},
  { value: "Registered Nurse - Continence Care Registered Nurse", label: "Registered Nurse - Continence Care Registered Nurse",},
  { value: "Registered Nurse - Continuing Educatio/Staff Development Registered Nurse", label: "Registered Nurse - Continuing Educatio/Staff Development Registered Nurse",},
  { value: "Registered Nurse - Critical Care Medicine Registered Nurse", label: "Registered Nurse - Critical Care Medicine Registered Nurse",},
  { value: "Registered Nurse - Diabetes Educator Registered Nurse", label: "Registered Nurse - Diabetes Educator Registered Nurse",},
  { value: "Registered Nurse - Peritoneal Dialysis Registered Nurse", label: "Registered Nurse - Peritoneal Dialysis Registered Nurse",},
  { value: "Registered Nurse - Emergency Registered Nurse", label: "Registered Nurse - Emergency Registered Nurse",},
  { value: "Registered Nurse - Enterostomal Therapy Registered Nurse", label: "Registered Nurse - Enterostomal Therapy Registered Nurse",},
  { value: "Registered Nurse - Flight Registered Nurse", label: "Registered Nurse - Flight Registered Nurse",},
  { value: "Registered Nurse - Gastroenterology Registered Nurse", label: "Registered Nurse - Gastroenterology Registered Nurse",},
  { value: "Registered Nurse - General Practice Registered Nurse", label: "Registered Nurse - General Practice Registered Nurse",},
  { value: "Registered Nurse - Gerentology Registered Nurse", label: "Registered Nurse - Gerentology Registered Nurse",},
  { value: "Registered Nurse - Hemodialysis Registered Nurse", label: "Registered Nurse - Hemodialysis Registered Nurse",},
  { value: "Registered Nurse - Home Health Registered Nurse", label: "Registered Nurse - Home Health Registered Nurse",},
  { value: "Registered Nurse - Hospice Registered Nurse", label: "Registered Nurse - Hospice Registered Nurse",},
  { value: "Registered Nurse - Infection Control Registered Nurse", label: "Registered Nurse - Infection Control Registered Nurse",},
  { value: "Registered Nurse - Infusion Therapy Registered Nurse", label: "Registered Nurse - Infusion Therapy Registered Nurse",},
  { value: "Registered Nurse - Lactation Consultant Registered Nurse", label: "Registered Nurse - Lactation Consultant Registered Nurse",},
  { value: "Registered Nurse - Maternal Newborn Registered Nurse", label: "Registered Nurse - Maternal Newborn Registered Nurse",},
  { value: "Registered Nurse - Medical-Surgical Registered Nurse", label: "Registered Nurse - Medical-Surgical Registered Nurse",},
  { value: "Registered Nurse - Neonatal Intensive Care Registered Nurse", label: "Registered Nurse - Neonatal Intensive Care Registered Nurse",},
  { value: "Registered Nurse - Low-risk Neonatal Registered Nurse", label: "Registered Nurse - Low-risk Neonatal Registered Nurse",},
  { value: "Registered Nurse - Nephrology Registered Nurse", label: "Registered Nurse - Nephrology Registered Nurse",},
  { value: "Registered Nurse - Neuroscience Registered Nurse", label: "Registered Nurse - Neuroscience Registered Nurse",},
  { value: "Registered Nurse - Nurse Massage Therapist", label: "Registered Nurse - Nurse Massage Therapist",},
  { value: "Registered Nurse - Nutrition Support Registered Nurse", label: "Registered Nurse - Nutrition Support Registered Nurse",},
  { value: "Registered Nurse - High-risk Obstetric Registered Nurse", label: "Registered Nurse - High-risk Obstetric Registered Nurse",},
  { value: "Registered Nurse - Inpatient Obstetric Registered Nurse", label: "Registered Nurse - Inpatient Obstetric Registered Nurse",},
  { value: "Registered Nurse - Occupational Health Registered Nurse", label: "Registered Nurse - Occupational Health Registered Nurse",},
  { value: "Registered Nurse - Oncology Registered Nurse", label: "Registered Nurse - Oncology Registered Nurse",},
  { value: "Registered Nurse - Ophthalmic Registered Nurse", label: "Registered Nurse - Ophthalmic Registered Nurse",},
  { value: "Registered Nurse - Orthopedic Registered Nurse", label: "Registered Nurse - Orthopedic Registered Nurse",},
  { value: "Registered Nurse - Ostomy Care Registered Nurse", label: "Registered Nurse - Ostomy Care Registered Nurse",},
  { value: "Registered Nurse - Otorhinolaryngology & Head-neck Registered Nurse", label: "Registered Nurse - Otorhinolaryngology & Head-neck Registered Nurse",},
  { value: "Registered Nurse - Pain Management Registered Nurse", label: "Registered Nurse - Pain Management Registered Nurse",},
  { value: "Registered Nurse - Pediatric Oncology Registered Nurse", label: "Registered Nurse - Pediatric Oncology Registered Nurse",},
  { value: "Registered Nurse - Pediatric Registered Nurse", label: "Registered Nurse - Pediatric Registered Nurse",},
  { value: "Registered Nurse - Perinatal Registered Nurse", label: "Registered Nurse - Perinatal Registered Nurse",},
  { value: "Registered Nurse - Plastic Surgery Registered Nurse", label: "Registered Nurse - Plastic Surgery Registered Nurse",},
  { value: "Registered Nurse - Psychiatric/Mental Health Registered Nurse", label: "Registered Nurse - Psychiatric/Mental Health Registered Nurse",},
  { value: "Registered Nurse - Adult Psychiatric/Mental Health Registered Nurse", label: "Registered Nurse - Adult Psychiatric/Mental Health Registered Nurse",},
  { value: "Registered Nurse - Child & Adolescent Psychiatric/Mental Health Registered Nurse", label: "Registered Nurse - Child & Adolescent Psychiatric/Mental Health Registered Nurse",},
  { value: "Registered Nurse - Registered Nurse First Assistant", label: "Registered Nurse - Registered Nurse First Assistant",},
  { value: "Registered Nurse - Rehabilitation Registered Nurse", label: "Registered Nurse - Rehabilitation Registered Nurse",},
  { value: "Registered Nurse - Reproductive Endocrinology/Infertility Registered Nurse", label: "Registered Nurse - Reproductive Endocrinology/Infertility Registered Nurse",},
  { value: "Registered Nurse - School Registered Nurse", label: "Registered Nurse - School Registered Nurse",},
  { value: "Registered Nurse - Urology Registered Nurse", label: "Registered Nurse - Urology Registered Nurse",},
  { value: "Registered Nurse - Ambulatory Women's Health Care Registered Nurse", label: "Registered Nurse - Ambulatory Women's Health Care Registered Nurse",},
  { value: "Registered Nurse - Wound Care Registered Nurse", label: "Registered Nurse - Wound Care Registered Nurse",},
  { value: "Radiology - Body Imaging Physician", label: "Radiology - Body Imaging Physician" },
  { value: "Radiology - Diagnostic Neuroimaging Physician", label: "Radiology - Diagnostic Neuroimaging Physician" },
  { value: "Radiology - Diagnostic Radiology Physician", label: "Radiology - Diagnostic Radiology Physician" },
  { value: "Radiology - Diagnostic Ultrasound Physician", label: "Radiology - Diagnostic Ultrasound Physician" },
  { value: "Radiology - Hospice & Palliative Medicine Physician", label: "Radiology - Hospice & Palliative Medicine Physician" },
  { value: "Radiology - Neuroradiology Physician", label: "Radiology - Neuroradiology Physician" },
  { value: "Radiology - Nuclear Radiology Physician", label: "Radiology - Nuclear Radiology Physician" },
  { value: "Radiology - Pediatric Radiology Physician", label: "Radiology - Pediatric Radiology Physician" },
  { value: "Radiology - Radiation Oncology Physician", label: "Radiology - Radiation Oncology Physician" },
  { value: "Radiology - Radiological Physics Physician", label: "Radiology - Radiological Physics Physician" },
  { value: "Radiology - Therapeutic Radiology Physician", label: "Radiology - Therapeutic Radiology Physician" },
  { value: "Radiology - Vascular & Interventional Radiology Physician", label: "Radiology - Vascular & Interventional Radiology Physician" },
  { value: "Surgery Physician", label: "Surgery Physician" },
  { value: "Surgery - Hospice & Palliative Medicine Physician", label: "Surgery - Hospice & Palliative Medicine Physician" },
  { value: "Surgery - Pediatric Surgery Physician", label: "Surgery - Pediatric Surgery Physician" },
  { value: "Surgery - Plastic & Reconstructive Surgery Physician", label: "Surgery - Plastic & Reconstructive Surgery Physician" },
  { value: "Surgery - Surgery of the Hand Physician", label: "Surgery - Surgery of the Hand Physician" },
  { value: "Surgery - Surgical Critical Care Physician", label: "Surgery - Surgical Critical Care Physician" },
  { value: "Surgery - Surgical Oncology Physician", label: "Surgery - Surgical Oncology Physician" },
  { value: "Surgery - Trauma Surgery Physician", label: "Surgery - Trauma Surgery Physician" },
  { value: "Surgery - Vascular Surgery Physician", label: "Surgery - Vascular Surgery Physician" },
  { value: "Thoracic Surgery (Cardiothoracic Vascular Surgery) Physician", label: "Thoracic Surgery (Cardiothoracic Vascular Surgery) Physician" },
  { value: "Transplant Surgery Physician", label: "Transplant Surgery Physician" },
  { value: "Urology Physician", label: "Urology Physician" },
  { value: "Urology - Female Pelvic Medicine & Reconstructive Surgery Physician", label: "Urology - Female Pelvic Medicine & Reconstructive Surgery Physician" },
  { value: "Urology - Pediatric Urology Physician", label: "Urology - Pediatric Urology Physician" },
  { value: "Assistant Behavioral Analyst", label: "Assistant Behavioral Analyst" },
  { value: "Behavioral Analyst", label: "Behavioral Analyst" },
  { value: "Behavior Technician", label: "Behavior Technician" },
  { value: "Clinical Neuropsychologist", label: "Clinical Neuropsychologist" },
  { value: "Counselor", label: "Counselor" },
  { value: "Counselor - Addiction (Substance Use Disorder) Counselor", label: "Counselor - Addiction (Substance Use Disorder) Counselor" },
  { value: "Counselor - Mental Health Counselor", label: "Counselor - Mental Health Counselor" },
  { value: "Counselor - Pastoral Counselor", label: "Counselor - Pastoral Counselor" },
  { value: "Counselor - Professional Counselor", label: "Counselor - Professional Counselor" },
  { value: "Counselor - School Counselor", label: "Counselor - School Counselor" },
  { value: "Drama Therapist", label: "Drama Therapist" },
  { value: "Marriage & Family Therapist", label: "Marriage & Family Therapist" },
  { value: "Poetry Therapist", label: "Poetry Therapist" },
  { value: "Psychoanalyst", label: "Psychoanalyst" },
  { value: "Psychologist", label: "Psychologist" },
  { value: "Psychologist - Addiction (Substance Use Disorder) Psychologist", label: "Psychologist - Addiction (Substance Use Disorder) Psychologist" },
  { value: "Psychologist - Adult Developmental & Aging Psychologist", label: "Psychologist - Adult Developmental & Aging Psychologist" },
  { value: "Psychologist - Clinical Psychologist", label: "Psychologist - Clinical Psychologist" },
  { value: "Psychologist - Clinical Child & Adolescent Psychologist", label: "Psychologist - Clinical Child & Adolescent Psychologist" },
  { value: "Psychologist - Cognitive & Behavioral Psychologist", label: "Psychologist - Cognitive & Behavioral Psychologist" },
  { value: "Psychologist - Counseling Psychologist", label: "Psychologist - Counseling Psychologist" },
  { value: "Psychologist - Exercise & Sports Psychologist", label: "Psychologist - Exercise & Sports Psychologist" },
  { value: "Psychologist - Family Psychologist", label: "Psychologist - Family Psychologist" },
  { value: "Psychologist - Forensic Psychologist", label: "Psychologist - Forensic Psychologist" },
  { value: "Psychologist - Group Psychotherapy Psychologist", label: "Psychologist - Group Psychotherapy Psychologist" },
  { value: "Psychologist - Health Psychologist", label: "Psychologist - Health Psychologist" },
  { value: "Psychologist - Health Service Psychologist", label: "Psychologist - Health Service Psychologist" },
  { value: "Psychologist - Intellectual & Developmental Disabilities Psychologist", label: "Psychologist - Intellectual & Developmental Disabilities Psychologist" },
  { value: "Psychologist - Prescribing (Medical) Psychologist", label: "Psychologist - Prescribing (Medical) Psychologist" },
  { value: "Psychologist - Psychoanalysis Psychologist", label: "Psychologist - Psychoanalysis Psychologist" },
  { value: "Psychologist - Rehabilitation Psychologist", label: "Psychologist - Rehabilitation Psychologist" },
  { value: "Psychologist - School Psychologist", label: "Psychologist - School Psychologist" },
  { value: "Social Worker", label: "Social Worker" },
  { value: "Social Worker - Clinical Social Worker", label: "Social Worker - Clinical Social Worker" },
  { value: "Social Worker - School Social Worker", label: "Social Worker - School Social Worker" },
  { value: "Chiropractor", label: "Chiropractor" },
  { value: "Chiropractor - Independent Medical Examiner Chiropractor", label: "Chiropractor - Independent Medical Examiner Chiropractor" },
  { value: "Chiropractor - Internist Chiropractor", label: "Chiropractor - Internist Chiropractor" },
  { value: "Chiropractor - Neurology Chiropractor", label: "Chiropractor - Neurology Chiropractor" },
  { value: "Chiropractor - Nutrition Chiropractor", label: "Chiropractor - Nutrition Chiropractor" },
  { value: "Chiropractor - Occupational Health Chiropractor", label: "Chiropractor - Occupational Health Chiropractor" },
  { value: "Chiropractor - Orthopedic Chiropractor", label: "Chiropractor - Orthopedic Chiropractor" },
  { value: "Chiropractor - Pediatric Chiropractor", label: "Chiropractor - Pediatric Chiropractor" },
  { value: "Chiropractor - Radiology Chiropractor", label: "Chiropractor - Radiology Chiropractor" },
  { value: "Chiropractor - Rehabilitation Chiropractor", label: "Chiropractor - Rehabilitation Chiropractor" },
  { value: "Chiropractor - Sports Physician Chiropractor", label: "Chiropractor - Sports Physician Chiropractor" },
  { value: "Chiropractor - Thermography Chiropractor", label: "Chiropractor - Thermography Chiropractor" },
  { value: "Advanced Practice Dental Therapist", label: "Advanced Practice Dental Therapist" },
  { value: "Dental Assistant", label: "Dental Assistant" },
  { value: "Dental Hygienist", label: "Dental Hygienist" },
  { value: "Dental Laboratory Technician", label: "Dental Laboratory Technician" },
  { value: "Dental Therapist", label: "Dental Therapist" },
  { value: "Dentist", label: "Dentist" },
  { value: "Dentist - Public Health Dentist", label: "Dentist - Public Health Dentist" },
  { value: "Dentist - Dentist Anesthesiologist", label: "Dentist - Dentist Anesthesiologist" },
  { value: "Dentist - Endodontist", label: "Dentist - Endodontist" },
  { value: "Dentist - General Practice Dentistry", label: "Dentist - General Practice Dentistry" },
  { value: "Dentist - Oral & Maxillofacial Dentist ", label: "Dentist - Oral & Maxillofacial Dentist " },
  { value: "Dentist - Oral & Maxillofacial Radiology Dentist ", label: "Dentist - Oral & Maxillofacial Radiology Dentist " },
  { value: "Dentist - Oral & Maxillofacial Surgery Dentist ", label: "Dentist - Oral & Maxillofacial Surgery Dentist " },
  { value: "Dentist - Orofacial Pain Dentist", label: "Dentist - Orofacial Pain Dentist" },
  { value: "Dentist - Orthodontics & Dentofacial Orthopedic Dentist", label: "Dentist - Orthodontics & Dentofacial Orthopedic Dentist" },
  { value: "Dentist - Pediatric Dentist", label: "Dentist - Pediatric Dentist" },
  { value: "Dentist - Periodontist", label: "Dentist - Periodontist" },
  { value: "Dentist - Prosthodontist", label: "Dentist - Prosthodontist" },
  { value: "Denturist", label: "Denturist" },
  { value: "Oral Medicinist", label: "Oral Medicinist" },
  { value: "Dietary Manager", label: "Dietary Manager" },
  { value: "Registered Dietetic Technician", label: "Registered Dietetic Technician" },
  { value: "Registered Dietitian", label: "Registered Dietitian" },
  { value: "Registered Dietitian - Gerontological Nutrition Registered Dietitian", label: "Registered Dietitian - Gerontological Nutrition Registered Dietitian" },
  { value: "Registered Dietitian - Metabolic Nutrition Registered Dietitian", label: "Registered Dietitian - Metabolic Nutrition Registered Dietitian" },
  { value: "Registered Dietitian - Obesity & Weight Management Nutrition Registered Dietitian", label: "Registered Dietitian - Obesity & Weight Management Nutrition Registered Dietitian" },
  { value: "Registered Dietitian - Oncology Nutrition Registered Dietitian", label: "Registered Dietitian - Oncology Nutrition Registered Dietitian" },
  { value: "Registered Dietitian - Pediatric Nutrition Registered Dietitian", label: "Registered Dietitian - Pediatric Nutrition Registered Dietitian" },
  { value: "Registered Dietitian - Pediatric Critical Care Nutrition Registered Dietitian", label: "Registered Dietitian - Pediatric Critical Care Nutrition Registered Dietitian" },
  { value: "Registered Dietitian - Renal Nutrition Registered Dietitian", label: "Registered Dietitian - Renal Nutrition Registered Dietitian" },
  { value: "Registered Dietitian - Sports Dietetics Nutrition Registered Dietitian", label: "Registered Dietitian - Sports Dietetics Nutrition Registered Dietitian" },
  { value: "Nutritionist", label: "Nutritionist" },
  { value: "Basic Emergency Medical Technician", label: "Basic Emergency Medical Technician" },
  { value: "Intermediate Emergency Medical Technician", label: "Intermediate Emergency Medical Technician" },
  { value: "Paramedic", label: "Paramedic" },
  { value: "Personal Emergency Response Attendant", label: "Personal Emergency Response Attendant" },
  { value: "Optometrist", label: "Optometrist" },
  { value: "Optometrist - Corneal & Contact Management Optometrist", label: "Optometrist - Corneal & Contact Management Optometrist" },
  { value: "Optometrist - Low Vision Rehabilitation Optometrist", label: "Optometrist - Low Vision Rehabilitation Optometrist" },
  { value: "Optometrist - Occupational Vision Optometrist", label: "Optometrist - Occupational Vision Optometrist" },
  { value: "Optometrist - Pediatric Optometrist", label: "Optometrist - Pediatric Optometrist" },
  { value: "Optometrist - Sports Vision Optometrist", label: "Optometrist - Sports Vision Optometrist" },
  { value: "Optometrist - Vision Therapy Optometrist", label: "Optometrist - Vision Therapy Optometrist" },
  { value: "Technician/Technologist - Contact Lens Technician/Technologist", label: "Technician/Technologist - Contact Lens Technician/Technologist" },
  { value: "Technician/Technologist - Contact Lens Fitter", label: "Technician/Technologist - Contact Lens Fitter" },
  { value: "Technician/Technologist - Ocularist", label: "Technician/Technologist - Ocularist" },
  { value: "Technician/Technologist - Ophthalmic Technician/Technologist", label: "Technician/Technologist - Ophthalmic Technician/Technologist" },
  { value: "Technician/Technologist - Ophthalmic Assistant", label: "Technician/Technologist - Ophthalmic Assistant" },
  { value: "Technician/Technologist - Optician", label: "Technician/Technologist - Optician" },
  { value: "Technician/Technologist - Optometric Assistant Technician", label: "Technician/Technologist - Optometric Assistant Technician" },
  { value: "Technician/Technologist - Optometric Technician", label: "Technician/Technologist - Optometric Technician" },
  { value: "Technician/Technologist - Orthoptist", label: "Technician/Technologist - Orthoptist" },
  { value: "Licensed Practical Nurse", label: "Licensed Practical Nurse" },
  { value: "Licensed Psychiatric Technician", label: "Licensed Psychiatric Technician" },
  { value: "Licensed Vocational Nurse", label: "Licensed Vocational Nurse" },
  { value: "Registered Nurse", label: "Registered Nurse" },
  { value: "Doula", label: "Doula" },
  { value: "Nurse Aid", label: "Nurse Aid" },
  { value: "Religious Nonmedical Nursing Personnel", label: "Religious Nonmedical Nursing Personnel" },
  { value: "Religious Nonmedical Practitioner", label: "Religious Nonmedical Practitioner" },
  { value: "Case Manager/Care Coordinator", label: "Case Manager/Care Coordinator" },
  { value: "Clinical Ethicist", label: "Clinical Ethicist" },
  { value: "Community Health Worker", label: "Community Health Worker" },
  { value: "Genetic Counselor (M.S.)", label: "Genetic Counselor (M.S.)" },
  { value: "Health & Wellness Coach", label: "Health & Wellness Coach" },
  { value: "Health Educator", label: "Health Educator" },
  { value: "Homeopath", label: "Homeopath" },
  { value: "Interpreter", label: "Interpreter" },
  { value: "Lactation Consultant (Non-RN)", label: "Lactation Consultant (Non-RN)" },
  { value: "Legal Medicine", label: "Legal Medicine" },
  { value: "Medical Genetics (Ph.D.)", label: "Medical Genetics (Ph.D.)" },
  { value: "Midwife", label: "Midwife" },
  { value: "Lay Midwife", label: "Lay Midwife" },
  { value: "Naprapath", label: "Naprapath" },
  { value: "Naturopath", label: "Naturopath" },
  { value: "Peer Specialist", label: "Peer Specialist" },
  { value: "Prevention Professional", label: "Prevention Professional" },
  { value: "Reflexologist", label: "Reflexologist" },
  { value: "Sleep Specialist Ph.D.", label: "Sleep Specialist Ph.D." },
  { value: "Pharmacist", label: "Pharmacist" },
  { value: "Pharmacy Technician", label: "Pharmacy Technician" },
  { value: "Advance Practice Midwife", label: "Advance Practice Midwife" },
  { value: "Anesthesiologist Assistant", label: "Anesthesiologist Assistant" },
  { value: "Clinical Nurse Specialist", label: "Clinical Nurse Specialist" },
  { value: "Certified Registered Nurse Anesthetist", label: "Certified Registered Nurse Anesthetist" },
  { value: "Nurse Practitioner", label: "Nurse Practitioner" },
  { value: "Nurse Practitioner - Acute Care Nurse Practitioner", label: "Nurse Practitioner - Acute Care Nurse Practitioner" },
  { value: "Nurse Practitioner - Adult Health Nurse Practitioner", label: "Nurse Practitioner - Adult Health Nurse Practitioner" },
  { value: "Nurse Practitioner - Community Health Nurse Practitioner", label: "Nurse Practitioner - Community Health Nurse Practitioner" },
  { value: "Nurse Practitioner - Critical Care Medicine Nurse Practitioner", label: "Nurse Practitioner - Critical Care Medicine Nurse Practitioner" },
  { value: "Nurse Practitioner - Family Nurse Practitioner", label: "Nurse Practitioner - Family Nurse Practitioner" },
  { value: "Nurse Practitioner - Gerontology Nurse Practitioner", label: "Nurse Practitioner - Gerontology Nurse Practitioner" },
  { value: "Nurse Practitioner - Neonatal Nurse Practitioner", label: "Nurse Practitioner - Neonatal Nurse Practitioner" },
  { value: "Nurse Practitioner - Critical Care Neonatal Nurse Practitioner", label: "Nurse Practitioner - Critical Care Neonatal Nurse Practitioner" },
  { value: "Nurse Practitioner - Obstetrics & Gynecology Nurse Practitioner", label: "Nurse Practitioner - Obstetrics & Gynecology Nurse Practitioner" },
  { value: "Nurse Practitioner - Occupational Health Nurse Practitioner", label: "Nurse Practitioner - Occupational Health Nurse Practitioner" },
  { value: "Nurse Practitioner - Pediatric Nurse Practitioner", label: "Nurse Practitioner - Pediatric Nurse Practitioner" },
  { value: "Nurse Practitioner - Critical Care Pediatric Nurse Practitioner", label: "Nurse Practitioner - Critical Care Pediatric Nurse Practitioner" },
  { value: "Nurse Practitioner - Perinatal Nurse Practitioner", label: "Nurse Practitioner - Perinatal Nurse Practitioner" },
  { value: "Nurse Practitioner - Primary Care Nurse Practitioner", label: "Nurse Practitioner - Primary Care Nurse Practitioner" },
  { value: "Nurse Practitioner - Psychiatric/Mental Health Nurse Practitioner", label: "Nurse Practitioner - Psychiatric/Mental Health Nurse Practitioner" },
  { value: "Nurse Practitioner - School Nurse Practitioner", label: "Nurse Practitioner - School Nurse Practitioner" },
  { value: "Nurse Practitioner - Women’s Health Nurse Practitioner", label: "Nurse Practitioner - Women’s Health Nurse Practitioner" },
  { value: "Physician Assistant", label: "Physician Assistant" },
  { value: "Physician Assistant - Medical Physician Assistant", label: "Physician Assistant - Medical Physician Assistant" },
  { value: "Physician Assistant - Surgical Physician Assistant", label: "Physician Assistant - Surgical Physician Assistant" },
  { value: "Pediatric Assistant", label: "Pediatric Assistant" },
  { value: "Podiatrist", label: "Podiatrist" },
  { value: "Podiatrist - Foot & Ankle Podiatrist", label: "Podiatrist - Foot & Ankle Podiatrist" },
  { value: "Podiatrist - Foot Surgery Podiatrist", label: "Podiatrist - Foot Surgery Podiatrist" },
  { value: "Podiatrist - Primary Pediatric Medicine Podiatrist", label: "Podiatrist - Primary Pediatric Medicine Podiatrist" },
  { value: "Podiatrist - Public Medicine Podiatrist", label: "Podiatrist - Public Medicine Podiatrist" },
  { value: "Podiatrist - Radiology Podiatrist", label: "Podiatrist - Radiology Podiatrist" },
  { value: "Podiatrist - Sports Medicine Podiatrist", label: "Podiatrist - Sports Medicine Podiatrist" },
  { value: "Anaplastologist", label: "Anaplastologist" },
  { value: "Art Therapist", label: "Art Therapist" },
  { value: "Clinical Exercise Physiologist", label: "Clinical Exercise Physiologist" },
  { value: "Dance Therapist", label: "Dance Therapist" },
  { value: "Developmental Therapist", label: "Developmental Therapist" },
  { value: "Kinesiotherapist", label: "Kinesiotherapist" },
  { value: "Massage Therapist", label: "Massage Therapist" },
  { value: "Mastectomy Fitter", label: "Mastectomy Fitter" },
  { value: "Music Therapist", label: "Music Therapist" },
  { value: "Occupational Therapist", label: "Occupational Therapist" },
  { value: "Occupational Therapy Assistant", label: "Occupational Therapy Assistant" },
  { value: "Orthotic Fitter", label: "Orthotic Fitter" },
  { value: "Orthotist", label: "Orthotist" },
  { value: "Pedorthist", label: "Pedorthist" },
  { value: "Physical Therapist", label: "Physical Therapist" },
  { value: "Physical Therapist Assistant", label: "Physical Therapist Assistant" },
  { value: "Prosthetist", label: "Prosthetist" },
  { value: "Pulmonary Function Technologist", label: "Pulmonary Function Technologist" },
  { value: "Recreation Therapist", label: "Recreation Therapist" },
  { value: "Recreation Therapist Assistant", label: "Recreation Therapist Assistant" },
  { value: "Rehabilitation Counselor", label: "Rehabilitation Counselor" },
  { value: "Rehabilitation Practitioner", label: "Rehabilitation Practitioner" },
  { value: "Certified Respiratory Therapist", label: "Certified Respiratory Therapist" },
  { value: "Respiratory Technologist", label: "Respiratory Technologist" },
  { value: "Athletic Trainer Specialist/Technologist", label: "Athletic Trainer Specialist/Technologist" },
  { value: "Audiologist", label: "Audiologist" },
  { value: "Audiology Assistant", label: "Audiology Assistant" },
  { value: "Speech-Language Pathologist", label: "Speech-Language Pathologist" },
  { value: "Radiologic Technologist", label: "Radiologic Technologist" },
  { value: "Radiology Practitioner Assistant", label: "Radiology Practitioner Assistant" },
  { value: "Cardiovascular Specialist/Technologist", label: "Cardiovascular Specialist/Technologist" },
  { value: "Pathology Specialist/Technologist", label: "Pathology Specialist/Technologist" }
]

export const MedicalLicense =[
  {value: "ACNP-BC", label : "Acute Care Nurse Practitioner-Board Certified"},
  {value: "ACNP", label : "Acute Care Nurse Practitioner"},
  {value: "AEMT", label : "Advanced Emergency Medical Technician"},
  {value: "ANP-BC", label : "Adult Health Nurse Practitioner-Board Certified"},
  {value: "ANP", label : "Adult Health Nurse Practitioner"},
  {value: "APRN", label : "Advanced Practice Registered Nurse"},
  {value: "Au.D.", label : "Doctor of Audiology"},
  {value: "BCaBA", label : "Board Certified Assistant Behavior Analyst"},
  {value: "BCBA", label : "Board Certified Behavior Analyst"},
  {value: "BCO", label : "Board Certified Ocularist"},
  {value: "BCOptom", label : "Board Certified Optician"},
  {value : "BSW", label:" Bachelors of Social Work"},
  {value : "CCA", label:" Certification for Clinical Anaplastologist"},
  {value : "CCH", label:" Certified Classical Homeopath"},
  {value : "CCMHC", label:" Certified Clinical Mental Health Counselor"},
  {value : "CCMNP-BC", label:" Critical Care Medicine Nurse Practitioner-Board Certified"},
  {value : "CCMNP", label:" Critical Care Medicine Nurse Practitioner"},
  {value : "CCNNP-BC", label:" Critical Care Neonatal Nurse Practitioner-Board Certified"},
  {value : "CCNNP", label:" Critical Care Neonatal Nurse Practitioner"},
  {value : "CCPNP-BC", label:" Critical Care Pediatric Nurse Practitioner-Board Certified"},
  {value : "CCPNP", label:" Critical Care Pediatric Nurse Practitioner"},
  {value : "CDA", label:" Certified Dental Assistant"},
  {value : "CDM", label:" Certified Dietary Manager"},
  {value : "CDT", label:" Certified Dental Technician"},
  {value : "CEP", label:" Clinical Exercise Physiologist"},
  {value : "CHNP-BC", label:" Community Health Nurse Practitioner-Board Certified"},
  {value : "CHNP", label:" Community Health Nurse Practitioner"},
  {value : "CM", label:" Certified Midwife"},
  {value : "CNM", label:" Certified Nurse Midwife"},
  {value : "CNS", label:" Certified Nutrition Specialist"},
  {value : "CNS", label:" Clinical Nurse Specialist"},
  {value : "CPC", label:" Certified Pastoral Counselor"},
  {value : "CPC", label:" Certified Professional Counselor"},
  {value : "CPM", label:" Certified Professional Midwife"},
  {value : "CPNP", label:" Certified Pediatric Nurse Practitioner"},
  {value : "CRC", label:" Certified Rehabilitation Counselor"},
  {value : "CRNA ", label:" Certified Registered Nurse Anesthetist"},
  {value : "CRT", label:" Certified Respiratory Therapist"},
  {value : "CTRS", label:" Certified Therapeutic Recreation Therapist"},
  {value : "D.C.", label:" Doctor of Chiropractic"},
  {value : "D.O.", label:" Doctor of Osteopathic Medicine"},
  {value : "DDS", label:" Doctor of Dental Surgery"},
  {value : "DMD", label:" Doctor of Dental Medicine"},
  {value : "DNP", label:" Doctor of Nursing Practice"},
  {value : "DPM", label:" Doctor of Podiatric Medicine"},
  {value : "DPT", label:" Doctor of Physical Therapist"},
  {value : "DTR", label:" Dietetic Technician, Registered"},
  {value : "EMR", label:" Emergency Medical Responder"},
  {value : "EMT", label:" Emergency Medical Technician"},
  {value : "FAAD", label:" Fellow of the American Academy of Dermatology"},
  {value : "FAAE", label:" Fellow of the American Academy of Endodontists"},
  {value : "FAAEM", label:" Fellow of the American Academy of Emergency Medicine"},
  {value : "FAAFP", label:" Fellow of the American Academy of Family Physicians"},
  {value : "FAAN", label:" Fellow of the American Academy of Neurology"},
  {value : "FAANS", label:" Fellow of the American Association of Neurological Surgeons"},
  {value : "FAAO", label:" Fellow of the American Academy of Orthodontists"},
  {value : "FAAOE", label:" Fellow of the American Academy of Oral Medicine"},
  {value : "FAAOMS", label:" Fellow of the American Association of Oral and Maxillofacial Surgeons"},
  {value : "FAAP", label:" Fellow of the American Academy of Pediatrics"},
  {value : "FAAPD", label:" Fellow of the American Academy of Pediatric Dentistry"},
  {value : "FACC", label:" Fellow of the American College of Cardiology"},
  {value : "FACD", label:" Fellow of the American College of Dentists"},
  {value : "FACEP", label:" Fellow of the American College of Emergency Physicians"},
  {value : "FACOG", label:" Fellow of the American College of Obstetricians and Gynecologists"},
  {value : "FACP", label:" Fellow of the American College of Physicians"},
  {value : "FACP", label:" Fellow of the American College of Prosthodontists"},
  {value : "FACS", label:" Fellow of the American College of Surgeons"},
  {value : "FAGD", label:" Fellow of the Academy of General Dentistry"},
  {value : "FAHA", label:" Fellow of the American Heart Association"},
  {value : "FASAM", label:" Fellow of the American Society of Addiction Medicine"},
  {value : "FASCRS", label:" Fellow of the American Society of Colon and Rectal Surgeons"},
  {value : "FASE", label:" Fellow of the American Society of Echocardiography"},
  {value : "FASGE", label:" Fellow of the American Society for Gastrointestinal Endoscopy"},
  {value : "FASM", label:" Fellow of the American Society of Microbiology"},
  {value : "FASMBS", label:" Fellow of the American Society for Metabolic and Bariatric Surgery"},
  {value : "FASN", label:" Fellow of the American Society of Nephrology"},
  {value : "FASNC", label:" Fellow of the American Society of Nuclear Cardiology"},
  {value : "FICD", label:" Fellow of the International College of Dentists"},
  {value : "FNP-BC", label:" Family Nurse Practitioner-Board Certified"},
  {value : "FNP", label:" Family Nurse Practitioner"},
  {value : "FNPC", label:" Family Nurse Practitioner-Certified"},
  {value : "GNP-BC", label:" Gerontology Nurse Practitioner-Board Certified"},
  {value : "GNP", label:" Gerontology Nurse Practitioner"},
  {value : "JD", label:" Juris Doctor"},
  {value : "LCSW", label:" Licensed Clinical Social Worker"},
  {value : "LICSW", label:" Licensed Independent Clinical Social Worker"},
  {value : "LMFT", label:" Licensed Marriage and Family Therapist"},
  {value : "LN", label:" Licensed Nutritionist"},
  {value : "LPC", label:" Licensed Professional Counselor"},
  {value : "LPN", label:" Licensed Practical Nurse"},
  {value : "LPT", label:" Licensed Psychiatric Technician"},
  {value : "LVN", label:" Licensed Vocational Nurse"},
  {value : "M.D.", label:" Doctor of Medicine"},
  {value : "MA", label:" Master of Arts"},
  {value : "MAGD", label:" Master of the Academy of General Dentistry"},
  {value : "MBA", label:" Master of Business Administration"},
  {value : "MPAS", label:" Master of Physician Assistant Study"},
  {value : "MPH", label:" Master of Public Health"},
  {value : "MS", label:" Master of Science"},
  {value : "MSD", label:" Master of Science in Dentistry"},
  {value : "MSN", label:" Master of Science in Nursing"},
  {value : "MSN", label:" Master of Science in Nursing"},
  {value : "MSW", label:" Masters of Social Work"},
  {value : "NCC", label:" National Certified Counselor"},
  {value : "ND", label:" Naturopathic Doctor"},
  {value : "NMT", label:" Neuromuscular Therapist"},
  {value : "NMT", label:" Nurse Massage Therapist"},
  {value : "NNP-BC", label:" Neonatal Nurse Practitioner-Board Certified"},
  {value : "NNP", label:" Neonatal Nurse Practitioner"},
  {value : "NP-C", label:" Nurse Practitioner-Certified"},
  {value : "NP", label:" Nurse Practitioner"},
  {value : "OBGYN-NP-BC", label:" Obstetrics & Gynecology Nurse Practitioner-Board Certified"},
  {value : "OBGYN-NP", label:" Obstetrics & Gynecology Nurse Practitioner"},
  {value : "OD", label:" Doctor of Optometry"},
  {value : "OHNP-BC", label:" Occupational Health Nurse Practitioner-Board Certified"},
  {value : "OHNP", label:" Occupational Health Nurse Practitioner"},
  {value : "OTD", label:" Doctor of Occupational Therapy"},
  {value : "OTR", label:" Occupational Therapist, Registered"},
  {value : "PA-C", label:" Physician Assistant-Certified"},
  {value : "PA", label:" Physician Assistant"},
  {value :"Paramedic", label:"Paramedic"},
  {value : "PCNP-BC", label:" Primary Care Nurse Practitioner-Board Certified"},
  {value : "PCNP", label:" Primary Care Nurse Practitioner"},
  {value : "Perinatal-NP-BC", label:" Perinatal Nurse Practitioner-Board Certified"},
  {value : "Perinatal-NP", label:" Perinatal Nurse Practitioner"},
  {value : "Ph.D.", label:" Doctor of Philosophy"},
  {value : "Pharm.D.", label:" Doctor of Pharmacy"},
  {value : "PMHNP-BC", label:" Psychiatric/Mental Health Nurse Practitioner-Board Certified"},
  {value : "PMHNP", label:" Psychiatric/Mental Health Nurse Practitioner"},
  {value : "PNP-BC", label:" Pediatric Nurse Practitioner-Board Certified"},
  {value : "PNP", label:" Pediatric Nurse Practitioner"},
  {value : "Psy.D.", label:" Doctor of Psychology"},
  {value : "RBT", label:" Registered Behavior Technician"},
  {value : "RD", label:" Registered Dietitian"},
  {value : "RDN", label:" Registered Dietitian Nutritionist"},
  {value : "RDT", label:" Registered Drama Therapist"},
  {value : "RKT", label:" Registered Kinesiotherapist"},
  {value : "RN", label:" Registered Nurse"},
  {value : "RPA-C", label:" Registered Physician Assistant-Certified"},
  {value : "SA-C", label:" Surgical Physician Assistant-Certified"},
  {value : "SNP-BC", label:" School Nurse Practitioner-Board Certified"},
  {value : "SNP", label:" School Nurse Practitioner"},
  {value : "WHNP-BC", label:" Women’s Health Nurse Practitioner-Board Certified"},
  {value : "WHNP", label:" Women’s Health Nurse Practitioner"},
]

export const chartColor = [
  { value: "green", label: "green" },
  { value: "red", label: "red" },
  { value: "#516cd3", label: "blue" },
  { value: "yellow", label: "yellow" },
]