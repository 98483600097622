import React, { useState } from "react";
import images from "../../../Assets/Images";

const Yearly = ({setIsOnboarding} : any) => {
  const [activeIndex, setActiveIndex] = useState(1);



  const handleClick = ()=>{
    setIsOnboarding(true)
  }

  const handleMouseEnter = (index: any) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(1); // Set the default active index when leaving
  };
  return (
    <div className="">
      <div className="d-flex justify-content-center gap-4 year">
        <div
          onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={activeIndex === 0 ? 'active essential m-0 d-flex justify-content-between flex-column' : 'essential m-0 d-flex justify-content-between flex-column'}
        >
          <div className="top">
            <h1 className="header">ESSENTIAL</h1>
            <div className="price-details">
              <h1 className="price">
                $800<span>/license/year</span>
              </h1>
              <p>Unlimited use</p>
            </div>
            <div className="details mt-3">
              <div className="d-flex">
                <img src={images.Icon} alt="icon" />
                <p className="d-inline">
                  Next-gen security:{" "}
                  <span>keep your patients PHI and identity safe</span>
                </p>
              </div>
              <div className="d-flex mt-2">
                <img src={images.Icon} alt="icon" />
                <p className="d-inline">
                  Medical Records:{" "}
                  <span>
                    26,000+ connected facility EHRs instantly deliver providers
                    the information they need
                  </span>
                </p>
              </div>
              <div className="d-flex mt-2">
                <img src={images.Icon} alt="icon" />
                <p className="d-inline">
                  Zero Cost EHR Integration:{" "}
                  <span>
                    we will integrate your EHR at no additional charge (1-way)
                  </span>
                </p>
              </div>
              <div className="d-flex mt-2">
                <img src={images.Icon} alt="icon" />
                <p className="d-inline">
                  Scheduling, Appointments, Digital Forms and Organization
                  Branding:{" "}
                  <span>
                    support patients, providers and admins for a best-in-class
                    virtual care experience
                  </span>
                </p>
              </div>
            </div>
          </div>
          {activeIndex === 0 ?
            <div className='contact' onClick={handleClick}>
              Get Started
            </div> :
            <div className='get-btn'>
              Get Started
            </div>}
        </div>
        <div onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={activeIndex === 1 ? 'active essential m-0 d-flex justify-content-between flex-column' : 'essential m-0 d-flex justify-content-between flex-column'}>
          <div className="top">
            <h1 className="header">ADVANCED</h1>
            <div className="price-details">
              <h1 className="price">
                $1000<span>/license/year</span>
              </h1>
              <p>Unlimited use</p>
            </div>
            <div className="details">
              <p className="heading mb-2">All Essential Features +</p>
              <div className="d-flex mt-2">
                <img src={images.Icon} alt="icon" />
                <p className="d-inline">
                  Digital Front Door:{" "}
                  <span>
                    deliver hybrid healthcare combining telehealth and your
                    patient portal all in one place
                  </span>
                </p>
              </div>
              <div className="d-flex mt-2">
                <img src={images.Icon} alt="icon" />
                <p className="d-inline">
                  Modern Referrals:{" "}
                  <span>
                    No more faxing, our platform supports HIPAA, ISO & Fed Ramp
                    secure referrals
                  </span>
                </p>
              </div>
            </div>
          </div>
          {activeIndex === 1 ?
            <div className='contact' onClick={handleClick}>
              Contact us
            </div> :
            <div className='get-btn'>
              Get Started
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Yearly;
