import React from "react";
import { useNavigate } from "react-router-dom";

// redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../Store";

// ui components
import Navbar from "./navbar";
import SidebarButtons from "./sidebarButtons";

// assets
import "./sidebar.css";
import images from "../../../../Assets/Images";
import { capitalize, getInitials } from "../../../utils";
import { getLocalStorage } from "../../Utils/AuthHandlers";
import ImageHandler from "../../../Common/ImageHandler";
import SidebarSkeleton from "../../../Common/Skeletons/SidebarSkeleton";

const Sidebar = ({ userData, isLoading }: any) => {
  const navigate = useNavigate();
  const { themeColor } = useSelector((state: RootState) => state.app);
  const SignUpUserName = getLocalStorage('SignUpUserName');
  const handleNavigate = () => {
    navigate("/home")
  };
  return (
    <>
      <div className="sidebar">
        {
          isLoading ? <SidebarSkeleton className="patient-sidebar-image"/> :
            <div
              className="sidebar-user patient-sidebar-user d-md-flex"
              style={{ display: "none" }}
            >
              {
                userData?.profile_image_path ? <img src={process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + userData?.profile_image_path} className="shadow-none blue_adminHomePamel cursor-pointer" onClick={handleNavigate} />
                  : userData?.first_name && userData?.last_name ? <div className="cursor-pointer para-img-div-profile d-flex" onClick={handleNavigate}>
                    <div className="para-circle-avatar-profile">
                      <p className="para-image-profile">{getInitials(userData.first_name + " " + userData.last_name)}</p>
                    </div>
                  </div> :
                    <ImageHandler
                      theme={themeColor}
                      imageName="DefaultImage"
                      className="shadow-none"
                      handleClick={handleNavigate}
                    />
              }

              {/* <img
          className="cursor-pointer"
          src={userData?.profile_image_path ? process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + userData?.profile_image_path : images.DefaultImage}
          alt="user"
          onClick={() => navigate("/home")}
        /> */}


              <h2>
                {userData?.first_name ? capitalize(userData?.first_name) + " " + capitalize(userData?.last_name) : typeof SignUpUserName == 'string' ? SignUpUserName : "-"}
              </h2>
              <button
                className="btn theme-btn account-btn"
                onClick={() => navigate("/my-account-information")}
              >
                my Profile
              </button>
            </div>
        }

        <SidebarButtons themeColor={themeColor} className="side-btns" />
        <Navbar themeColor={themeColor} />
      </div>
    </>
  );
};

export default Sidebar;
