import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HippaConsentPayload, InformationReleasePayload, MedicalHistoryPayload, PatienInformationPayload, TelehealthConsentPayload } from '../../Interfaces/FormsInterface/formInterFace';

interface FormDataMedicalhistory {
    [condition: string]: string;
    signature: string;
}
interface HIPPAConscentForm {
    firstName: string;
    lastName: string;
    birthDate: string;
    signature: string;
}
interface TelhealthConscentForm {
    clientName: string;
    birthDate: string;
    relationToClient: string;
    witness: string;
    offeredCopy:string
    signature:string;
}
interface InformationReleseAuthorizationForm {
    clientName: string;
    birthDate: string;
    providerName: string;
    providerEmail: string;
    providerPhone: string;
    providerFax: string;
    recipientName: string;
    recipientEmail: string;
    recipientPhone: string;
    recipientFax: string;
    patientFirstName: string;
    patientLastName: string;
    Date: string;
    gardiunFirstName: string;
    gardiunLastName: string;
    legalRelationship: string;
    Term: string[],
    informationTodisclosed: string[];
    signature:string;
}
interface PatientProfileState {
    FormDataPatientInformation: PatienInformationPayload;
    FormDataMedicalhistory: MedicalHistoryPayload;
    HIPPAConscentForm: HippaConsentPayload;
    TelhealthConscentForm:TelehealthConsentPayload;
    InformationReleseAuthorizationForm:InformationReleasePayload;
    isFirstLogin: boolean;
    IsUserLoginFirstTime:boolean
}

const initialState: PatientProfileState = {
    FormDataPatientInformation: {} as PatienInformationPayload,
    FormDataMedicalhistory: {} as MedicalHistoryPayload,
    HIPPAConscentForm: {} as HippaConsentPayload,
    TelhealthConscentForm: {} as TelehealthConsentPayload,
    InformationReleseAuthorizationForm :{} as InformationReleasePayload,
    isFirstLogin: false,
    IsUserLoginFirstTime:false
};

const patientFormProfileSlice = createSlice({
    name: 'patientFormsData',
    initialState,
    reducers: {
        setPatientInformation: (state, action: PayloadAction<PatienInformationPayload>) => {
            state.FormDataPatientInformation = action.payload;
        },
        setPatientMedicalHistory: (state, action: PayloadAction<any>) => {
            state.FormDataMedicalhistory = action.payload;
        
        },
        setHIPPAConscentFormData: (state, action: PayloadAction<any>) => {
            state.HIPPAConscentForm ={...action.payload ,signature:action.payload.imageURL};
        },
        setTelhealthConscentFormData:(state, action: PayloadAction<any>) => {
            state.TelhealthConscentForm = action.payload;
        },
        setInformationReleseAuthorizationFormData:(state, action: PayloadAction<any>) => {
            state.InformationReleseAuthorizationForm =action.payload;
        },
        setIsFirstLogin: (state, action: PayloadAction<boolean>) => {
            state.IsUserLoginFirstTime = action.payload;
        },
        resetState: () => initialState,
    },
});

export const { setPatientInformation, setPatientMedicalHistory ,setHIPPAConscentFormData,setTelhealthConscentFormData,setInformationReleseAuthorizationFormData ,setIsFirstLogin, resetState} = patientFormProfileSlice.actions;

export default patientFormProfileSlice;
