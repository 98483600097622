import React, { useState } from 'react'

// redux
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setIsUserSignIn } from '../../../Store/Slicers/commonSlicer'

// assets
import LandingLogo from '../../../Assets/Svgs/LandingLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CloseModelIcon } from '../../../Assets/Svgs';
import '../../Admin/Core/AuthModule/Auth.css'

interface prop {
  btn : string,
  redirect : string
}

const SignInHeader = ({btn,redirect}:prop) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleSignIn = () => {
    setShowModal(true);
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const closeModal = () => {
    setShowModal(false);
  }

  const handleSubmit: any = async (values: any) => {
    setShowModal(false);
    if (values.email == "example12@example.com" && values.password == 'Test@123') {
      dispatch(setIsUserSignIn(true));
    } else {
      dispatch(setIsUserSignIn(false));
    }
  };

  return (
    <>
      <div className="logo-panel d-flex justify-content-between">
        <div className="logo" onClick={() => navigate("/")}>
          <img src={LandingLogo} alt="logo" />
        </div>
        <div className="d-flex gap-2 btns align-items-center ">
          <button className="btn-sign" onClick={handleSignIn}>Sign In</button>
          <button className="btn-started" onClick={() => navigate(redirect)}>{btn}</button>
        </div>
      </div>
      {showModal &&
        <>
          <div className="modal-backdrop" onClick={closeModal} />
          <div
            className={`modal fade ${showModal ? "show" : ""} signIn-modal`}
            style={{ display: showModal ? "block" : "none" }}
          >
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md">
              <div className="modal-body p-0">
                <div className="modal-content">
                  <div className="d-flex justify-content-between p-2">
                    <h3 className="modal-title">Demonstration Sign In</h3>
                    <span className="close-icon-invite" data-bs-dismiss="modal">
                      {/* <FontAwesomeIcon icon={faClose} onClick={closeModal} /> */}
                      <img src={CloseModelIcon} alt ="Close" className='close-model-icon'style={{width:"20px"}}  onClick={closeModal}/>
                    </span>
                  </div>
                  <hr />
                  <Formik
                    initialValues={{
                      password: "Test@123",
                      email: 'example12@example.com',
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="">
                          <div className="">
                            <div className="row">
                              <div className="col-lg-12 pb-2">
                                <div className="form-group auth-form">
                                  {/* <label>Email</label> */}
                                  <Field className={`form-control${isSubmitting ? ' is-invalid' : ''}`} type="text" name="email" placeholder="example@example.com" />
                                </div>
                              </div>
                              <div className="col-lg-12 pb-2">
                                <div className="form-group auth-form">
                                  {/* <label className="">Password</label> */}
                                  <Field className={`form-control${isSubmitting ? ' is-invalid' : ''}`} type="password" name="password" placeholder='Test@123' />
                                </div>
                              </div>
                            </div>
                            <div style={{    textAlign: "center"}}>
                              <button
                                className="btn theme-btn"
                                type="submit"
                                style={{width: "453px",height: "55px"}}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default SignInHeader