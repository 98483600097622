import React from 'react'
import images from '../../../../Assets/Images'
import CBSelect from '../../../../Components/Common/CBSelect/CBSelect'
import { Specialty, State } from '../Helpers/OptionArray'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import CBSelectState from '../../../../Components/Common/CBSelect/CBSelectState'
import { USAStatesCB } from '../../../../Components/utils'
import * as Yup from 'yup';
interface formData {
    practiceName: string,
    numberOfProviders: string,
    billingAddress: string,
    zipCode: number | '',
    State: string
}

const Step2 = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {

    const initialValues: formData = {
        practiceName: '',
        numberOfProviders: '',
        billingAddress: '',
        zipCode: '',
        State: '',
    }
    const validationSchema = Yup.object().shape({
        practiceName: Yup.string(),
        numberOfProviders: Yup.string(),
        billingAddress: Yup.string(),
        zipCode: Yup.string()
        .matches(/^\d{5}(-\d{4})?$/, 'ZIP Code must be in the format XXXXX OR XXXXX-XXXX'),
        State: Yup.string(),

    })
    const handleSubmit = () => {
        handleCheckoutButtonClick()
    }

    return (

        <div className='w-100'>
            <div className='d-flex onboarding-main-flow'>
                <div className='d-flex flex-column onboarding-stepping' style={{ gap: "60px" }}>
                    <div className='d-flex flex-column' style={{ gap: "12px" }}>
                        <p className='p-top'>
                            STEP 2
                        </p>
                        <div>
                            <h1 className='onboarding-heading'>Tell Us About Your Organization</h1>
                        </div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {
                            (formik) => (
                                <Form id='step2-form'>
                                    <div className='onboarding-step d-flex flex-column gap-4'>
                                        <div className="d-flex flex-column gap-4 dropdown-onboarding">
                                            <div className='d-flex flex-column w-100 gap-2'>
                                                <label className='onboarding-user'>Practice Name</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control w-100 ${formik.values.practiceName.length > 0 ? '' : 'input-user'}`}
                                                    name="practiceName"
                                                    placeholder='Enter Name'
                                                />
                                            </div>
                                            <div className='d-flex flex-column w-100 gap-2'>
                                                <label className='onboarding-user'>Number of Providers</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control w-100 ${formik.values.numberOfProviders.length > 0 ? '' : 'input-user'}`}
                                                    name="numberOfProviders"
                                                    placeholder='2'
                                                />
                                            </div>
                                            <div className='d-flex flex-column w-100'>
                                                <p className='onboarding-user'>Specialty</p>
                                                <CBSelect options={Specialty} className='onbarding-dropdown' placeholder='Select Specialty' />
                                            </div>
                                            <div className='d-flex flex-column w-100 gap-2'>
                                                <label className='onboarding-user'>Billing Address</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control w-100 ${formik.values.billingAddress.length > 0 ? '' : 'input-user'}`}
                                                    name="billingAddress"
                                                    placeholder='Enter Address'
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-4 dropdown-onboarding">
                                            <div className='d-flex flex-column w-100 gap-2'>
                                                <p className='onboarding-user'>State</p>
                                                <div className="d-flex align-items-center">
                                                    <CBSelectState options={USAStatesCB} placeholder='Select State' seletedItem={formik.values.State} onChange={(option: string) => {
                                                        if (option?.length === 2) {
                                                            formik.setFieldValue('State', option)
                                                        }

                                                        else {
                                                            const validStatesLAbel = USAStatesCB.filter(state => state.label?.toUpperCase() === option?.toUpperCase());
                                                            if (validStatesLAbel?.length > 0) {
                                                                formik.setFieldValue('State', validStatesLAbel[0]?.value)
                                                            }
                                                            else {
                                                                formik.setFieldValue('State', option)

                                                            }

                                                        }
                                                    }} className={`${formik.touched.State && formik.errors.State ? 'error-border' : ''} w-100 m-0 StateDropdown-class`} />
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column w-100 gap-2'>
                                                <label className='onboarding-user'>Zip Code</label>
                                                <Field
                                                    type="text"
                                                    // className={`form-control w-100 ${formik.values.zipCode ? '' : 'input-user'}`}
                                                    className={`${formik.touched.zipCode && formik.errors.zipCode ? 'error-border' : ''} form-control w-100`}
                                                    name="zipCode"
                                                    placeholder='Enter zip code'
                                                />
                                                           <p className="formik-validation-red-message">
          {formik.validateOnBlur &&formik.errors.zipCode ? formik.errors.zipCode:null} 
        </p> 
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }

                    </Formik>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={images.OnBoardingSideImage} alt="" className='onboarding-img' />
                </div>

            </div>
            <div className='d-flex justify-content-between col-lg-5 onboarding-btns'>
                <button className='onboarding-back' onClick={handleBackButtonClick}>Back</button>
                <button className='onboarding-checkout' type='submit' form='step2-form'>Next</button>
            </div>
        </div>
    )
}

export default Step2