import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import images from '../../../../Assets/Images';
import CBSelect from '../../../../Components/Common/CBSelect/CBSelect';
import { option } from '../Helpers/OptionArray';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { formatPhoneNumber } from '../../../../Components/utils';

interface formData {
    firstName: string,
    lastName: string,
    workEmail: string,
    phoneNumber: number | ''
}



const Step1 = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {

    const initialValues: formData = {
        firstName: '',
        lastName: '',
        workEmail: '',
        phoneNumber: ''
    }
const validationSchema= Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        workEmail: Yup.string(),
        phoneNumber: Yup.string()
        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone number must be in the format (XXX) XXX-XXXX'),


    })
    const handleSubmit = () => {
        handleCheckoutButtonClick()
    }


    return (

        <div className='w-100'>
            <div className='d-flex onboarding-main-flow'>
                <div className='d-flex flex-column onboarding-stepping' style={{ gap: "60px" }}>
                    <div className='d-flex flex-column' style={{ gap: "12px" }}>
                        <p className='p-top'>
                            STEP 1
                        </p>
                        <div>
                            <h1 className='onboarding-heading'>Let's Get Your Account Set Up!</h1>
                            <p className='onboarding-detail'>One of our client success
                                specialist will be contacting
                                you to schedule training</p>
                        </div>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}>
                        {
                            (formik) => (


                                <Form id='step1-form'>
                                    <div className='onboarding-step'>
                                        <div>
                                            <div className="row">
                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                    <div className="form-group position-relative">
                                                        <label className='onboarding-user'>First Name</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control w-100 ${formik.values.firstName.length > 0 ? '' : 'input-user'}`}
                                                            name="firstName"
                                                            placeholder='Enter first name'
                                                        />
                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.firstName.length > 0 ? 'check' : 'd-none'}`} />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                    <div className="form-group position-relative">
                                                        <label className='onboarding-user'>Last Name</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control w-100 ${formik.values.lastName.length > 0 ? '' : 'input-user'}`}
                                                            name="lastName"
                                                            placeholder='Enter last name'
                                                        />
                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.lastName.length > 0 ? 'check' : 'd-none'}`} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                    <div className="form-group position-relative">
                                                        <label className='onboarding-user'>Work Email</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control w-100 ${formik.values.workEmail.length > 0 ? '' : 'input-user'}`}
                                                            name="workEmail"
                                                            placeholder='Enter email'
                                                        />
                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.workEmail.length > 0 ? 'check' : 'd-none'}`} />
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                    <div className="form-group position-relative">
                                                        <label className='onboarding-user'>Phone Number</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control w-100 ${formik.values.phoneNumber ? '' : 'input-user'}`}
                                                            name="phoneNumber"
                                                            placeholder='Enter phone number'
                                                            onChange={(e:any)=>{
                                                                const formattedValue = formatPhoneNumber(e.target.value);
                                                                formik.setFieldValue('phoneNumber', formattedValue);
                                                                  }}
                                                                
                                                        />
                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.phoneNumber ? 'check' : 'd-none'}`} />
                                                        <p className="formik-validation-red-message">
          {formik.validateOnBlur &&formik.errors.phoneNumber ? formik.errors.phoneNumber:null} 
        </p> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p className='onboarding-user'>I am a</p>
                                                <CBSelect options={option} className='onbarding-dropdown' placeholder="Select Role" />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={images.OnBoardingSideImage} alt="" className='onboarding-img' />
                </div>
            </div>
            <div className='d-flex justify-content-between col-lg-5 onboarding-btns'>
                <button className='onboarding-back' disabled onClick={handleBackButtonClick}>Back</button>
                <button className='onboarding-checkout' type='submit' form='step1-form'>Next</button>
            </div>
        </div>
    )
}

export default Step1