// aws imports
import { Auth } from "aws-amplify";

// redux
import { store } from "../../../Store";
import PatientProfileSlice from "../../../Store/Slicers/ProfileInformation/patientProfileSlice";
import UserMessageSlice from "../../../Store/Slicers/Messages/userMessageSlicer";
import AdminProfileSlice from "../../../Store/Slicers/ProfileInformation/adminProfileSlice";
import ProviderProfileSlice from "../../../Store/Slicers/ProfileInformation/providerProfileSlice";

export async function fetchRefreshToken() {
  try {
    const session = await Auth.currentSession();
    const refreshToken = session.getRefreshToken().getToken();
    return { status: true, data: { token: refreshToken } };
  } catch (error) {
    return { status: false, error_message: error };
  }
}

export async function logoutHandler() {
  try {
    await Auth.signOut();
    localStorage.clear();
    store.dispatch(PatientProfileSlice.actions.resetState());
    store.dispatch(AdminProfileSlice.actions.resetState());
    store.dispatch(ProviderProfileSlice.actions.resetState());
    store.dispatch(UserMessageSlice.actions.resetState());
    window.location.assign("/login");
  } catch (error) {
    console.error("Sign-out error:", error);
  }
}

export const setLocalStorage = (itemName: string, itemValue: any) => {
  localStorage.setItem(itemName, itemValue);
};

export const deleteLocalStorage = (itemName: string) => {
  localStorage.removeItem(itemName);
};

export const getLocalStorage = (itemName: string) => {
  try {
    return JSON.parse(localStorage.getItem(itemName) || "{}");
  } catch (error) {
    return localStorage.getItem(itemName);
  }
};
