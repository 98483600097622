import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppState {
  medication_data: any[];
}

const initialState: AppState = {
  medication_data: [],
};

export const meditationSection = createSlice({
  name: "meditationSection",
  initialState,
  reducers: {
    meditation: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.medication_data = action.payload;
    },
    resetState: () => initialState
  },
});

export const { meditation,resetState } = meditationSection.actions;
