// aws imports
import { Auth } from "aws-amplify";

// store
import { store } from "../../../Store";
import ProviderProfileSlice from "../../../Store/Slicers/ProfileInformation/providerProfileSlice";

export async function logoutHandler() {
  try {
    await Auth.signOut();
    localStorage.clear()
    store.dispatch(ProviderProfileSlice.actions.resetState());
    window.location.assign("/provider/login");
  } catch (error) {
    console.error("Sign-out error:", error);
  }
}
 
export const setLocalStorage = (itemName: string, itemValue: any) => {
  localStorage.setItem(itemName, itemValue);
};

export const deleteLocalStorage = (itemName: string) => {
  localStorage.removeItem(itemName);
};

export const getLocalStorage = (itemName: string) => {
  try {
    return JSON.parse(localStorage.getItem(itemName) || "{}");
  } catch (error) {
    return localStorage.getItem(itemName);
  }
};
