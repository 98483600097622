import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const usePricing = () => {
  const faqItems = [
    {
      question: "Is there a free trial available?",
      answer: "Answer to the first question...",
    },
    {
      question: "Can I change my plan later?",
      answer: "Answer to the second question...",
    },
    {
      question: "What is your cancellation policy?",
      answer: "Answer to the third question...",
    },
    {
      question: "Can other info be added to an invoice?",
      answer: "Answer to the fourth question...",
    },
    {
      question: "How does billing work?",
      answer: "Answer to the fifth question...",
    },
    {
      question: "How do I change my account email?",
      answer: "Answer to the sixth question...",
    },
  ];

  const [isMonthly, setIsMonthly] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(false)

  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleAccordionToggle = (index: any) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleButtonClick = () => {
    setIsMonthly(!isMonthly);
  };

  const navigate = useNavigate()
  return {
    isMonthly,
    handleButtonClick,
    faqItems,
    openAccordionIndex,
    handleAccordionToggle,
    navigate,
    setIsOnboarding,
    isOnboarding
  };
};
