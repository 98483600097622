import React, { useState } from 'react'
import images from '../../../../Assets/Images'
import { Registration, Service } from '../Helpers/OptionArray'
import CBSelect from '../../../../Components/Common/CBSelect/CBSelect'
import { Field, Form, Formik } from 'formik'

interface formData {
    firstName: string,
    lastName: string,
    adminEmail: string,

}

const Step3 = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {

    const [isServiceHover, setIsServiceHover] = useState<boolean>(false)
    const [isRegistrationHover, setIsRegistrationHover] = useState<boolean>(false)

    const initialValues: formData = {
        firstName: '',
        lastName: '',
        adminEmail: '',
    }

    const handleSubmit = () => {
        handleCheckoutButtonClick()
    }

    return (
        <div className='w-100'>
            <div className='d-flex onboarding-main-flow'>
                <div className='d-flex flex-column onboarding-stepping' style={{ gap: "60px" }}>
                    <div className='d-flex flex-column' style={{ gap: "12px" }}>
                        <p className='p-top'>
                            STEP 3
                        </p>
                        <div>
                            <h1 className='onboarding-heading'>Let’s Set Up Your Platform</h1>
                            <p className='onboarding-detail'>One of our client success
                                specialist will be contacting
                                you to schedule training</p>
                        </div>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}>
                        {
                            (formik) => (
                                <Form id='step3-form'>
                                    <div className='onboarding-step d-flex flex-column gap-4'>
                                        <div className="d-flex gap-4">
                                            <div className='d-flex flex-column w-100'>
                                                <p className='onboarding-user'>Will you be offering “Visit Now” services or “Virtual Appointments” only?</p>
                                                <div className="d-flex align-items-center gap-2">
                                                    <CBSelect options={Service} className='onbarding-dropdown' placeholder='Select Telehealth Service' />
                                                    <div className='select-info d-flex justify-content-center position-relative' onMouseEnter={() => setIsServiceHover(true)} onMouseLeave={() => setIsServiceHover(false)}>
                                                        <img src={images.SelectInfo} alt="" className='mt-2' />
                                                        {
                                                            isServiceHover &&
                                                            <>
                                                                <div className='position-absolute d-flex flex-column' style={{ top: "20px" }}>
                                                                    <div className='hover-triangle'></div>
                                                                    <div className='hover-div'>
                                                                        <div>
                                                                            Enabling a "Visit Now" option for patients provides them with the opportunity to promptly connect with your healthcare providers. Providers assigned to "Visit Now" shifts will be matched with patients who choose this option, and they won't be available for virtual appointment scheduling during these shifts
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-4">
                                            <div className='d-flex flex-column gap-2 w-100'>
                                                <p className='onboarding-user'>Do you want to make digital patient registration forms available to patients and admins?</p>
                                                <div className="d-flex align-items-center gap-2">
                                                    <CBSelect options={Registration} className='onbarding-dropdown' placeholder='Enable Digital Forms' />
                                                    <div className='select-info d-flex justify-content-center position-relative' onMouseEnter={() => setIsRegistrationHover(true)} onMouseLeave={() => setIsRegistrationHover(false)}>
                                                        <img src={images.SelectInfo} alt="" className='mt-2' />
                                                        {
                                                            isRegistrationHover &&
                                                            <>
                                                                <div className='position-absolute d-flex flex-column' style={{ top: "20px" }}>
                                                                    <div className='hover-triangle'></div>
                                                                    <div className='hover-div'>
                                                                        <div>
                                                                            Your telehealth platform can offer patients the following HIPAA approved patient registration forms:
                                                                            <ul>
                                                                                <li>Patient Information</li>
                                                                                <li>Medical History</li>
                                                                                <li>HIPAA Consent</li>
                                                                                <li>Telehealth Consent</li>
                                                                                <li>Information Release Authorization</li>
                                                                            </ul>
                                                                            Patients can complete these forms and save or share them. Admins can access, share and print these forms
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="onboarding-user">Who will be your Admin?</p>
                                        <div className="row">
                                            <div className='col-lg-6 col-md-6 pb-4'>
                                                <label className='onboarding-user pb-2'>First Name</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control w-100 ${formik.values.firstName.length > 0 ? '' : 'input-user'}`}
                                                    name="firstName"
                                                    placeholder='Enter first name'
                                                />
                                            </div>
                                            <div className='col-lg-6 col-md-6 pb-4'>
                                                <label className='onboarding-user pb-2'>Last Name</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control w-100 ${formik.values.lastName.length > 0 ? '' : 'input-user'}`}
                                                    name="lastName"
                                                    placeholder='Enter last name'
                                                />
                                            </div>
                                            <div className='d-flex flex-column w-100 gap-2'>
                                                <label className='onboarding-user'>Admin Email</label>
                                                <Field
                                                    type="text"
                                                    className={`form-control w-100 ${formik.values.adminEmail.length > 0 ? '' : 'input-user'}`}
                                                    name="adminEmail"
                                                    placeholder='Enter admin email'
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </Form>
                            )
                        }

                    </Formik>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={images.OnBoardingSideImage} alt="" className='onboarding-img' />
                </div>
            </div>
            <div className='d-flex justify-content-between col-lg-5 onboarding-btns'>
                <button className='onboarding-back' onClick={handleBackButtonClick}>Back</button>
                <button className='onboarding-checkout' type='submit' form='step3-form'>Next</button>
            </div>
        </div>
    )
}

export default Step3