import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons';

import SketchPicker from "react-color";

//css
import './ImageUploadModal.scss'
import { Icons } from '../StepContent/ThirdContent';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedColor } from '../../../../../../../Store/Slicers/OnBoarding/OnBoardingSlicer';
import { RootState } from '../../../../../../../Store';
import { CloseModelIcon } from '../../../../../../../Assets/Svgs';

interface ImageUploadModalProps {
    showModal: boolean;
    handleCloseModal: () => void;
    handleUpload: () => void;
    handleImageChangeModal: (file: any) => void;
    uploadedImage: string | null;
    handleRemoveImage: () => void;
    handleImageSelection: (file: any) => void;
    title?: string;
    icons?: any
}

const ImageUploadModal = ({ showModal, handleCloseModal, handleUpload, handleImageChangeModal, uploadedImage, handleRemoveImage, icons, handleImageSelection, title }: ImageUploadModalProps) => {

    // const ImageChange = (img : string)=>{
    //     handleImageSelection()
    // }
    const { selectedIconColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)

    const [isSelected, setIsSelected] = useState<string | null>(null)

    const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false)
    const [iconColor, setIconColor] = useState(selectedIconColor)

    const colorPickerRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch()


    const handleSelected = (index: string, item: string) => {
        setIsSelected(index)
        handleImageSelection(item)
        // switch (itemName) {
        //     case 'outlines':
        //         break;
        //     case 'colorOutlines':
        //         handleImageSelection(item.colorOutlines)
        //         break;

        //     case 'filled':
        //         handleImageSelection(item.filled)
        //         break;

        //     case 'selectedOutlines':
        //         handleImageSelection(item)
        //         break;

        // }
    }

    const handlePickerOpen = () => {
        setIsColorPickerOpen(true);
    };
    const handleColorChange = (color: any, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        setIconColor(color.hex);
        setIsColorPickerOpen(true);
        dispatch(setSelectedColor(color.hex));
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setIsColorPickerOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [colorPickerRef]);

    return (
        <>
            <div className="modal-backdrop" onClick={handleCloseModal} />
            <div className={`modal fade ${showModal ? "show" : ""} `} style={{ display: showModal ? "block" : "none" }} >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md">
                    <div className="warning-model modal-body p-0">
                        <div className="d-flex modal-content gap-5">
                            <div className="d-flex flex-column gap-3">
                                <div className="d-flex justify-content-between p-0">
                                    <h3 className="modal-title">{title} Icons</h3>
                                    <span className="close-icon-invite" data-bs-dismiss="modal" style={{ fontSize: "24px" }}>
                                        {/* <FontAwesomeIcon icon={faClose} onClick={handleCloseModal} color='#555555' /> */}
                                        <img src={CloseModelIcon} alt ="Close" className='close-model-icon'style={{width:"20px"}}  onClick={handleCloseModal}/>
                                    </span>
                                </div>
                                <div>
                                    <hr className='m-0' />
                                </div>
                            </div>
                            <div className='d-flex flex-column '>
                                <div className='d-flex flex-column gap-2'>
                                    <p className='warning-second-message text-center'>
                                        <div className={`fileuploadbox position-relative`} style={{ width: "100%" }}>
                                            <div className='pb-3'>
                                                <p className='d-flex pb-2'>OUTLINED</p>
                                                <div className='d-flex gap-3 flex-wrap all-icons'>
                                                    {icons && icons.outlines.map((item: string, index: number) => {

                                                        return (
                                                            <>
                                                                <div className={`icon-images-div ${isSelected === index + 'outlines' ? "icon-images-div-active" : ""}`} onClick={() => { handleSelected(index + 'outlines', item) }}>
                                                                    <img src={item} alt="" height={50} />
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className='pb-3'>
                                                <div className='d-flex justify-content-between align-items-center color-picker pb-2'>
                                                    <p>SELECTIVE COLOR OUTLINE</p>
                                                    <div className='d-flex gap-2 align-items-center color-div dropdown-toggle cursor-pointer' ref={colorPickerRef} onClick={handlePickerOpen}>
                                                        <div className='color-box position-relative' style={{ backgroundColor: iconColor }} >
                                                            {isColorPickerOpen &&
                                                                <div className="color-picker-container">
                                                                    <SketchPicker
                                                                        // className='first-content-picker'
                                                                        color={iconColor}
                                                                        onChange={handleColorChange}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        <p>{iconColor.toUpperCase()}</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex gap-3 flex-wrap all-icons'>
                                                    {icons && icons.selectedColoredOutline.map((item: string, index: number) => {
                                                        return (
                                                            <>
                                                                <div className={`icon-images-div ${isSelected === index + 'selectedOutlines' ? "icon-images-div-active" : ""}`} onClick={() => { handleSelected(index + 'selectedOutlines', item) }}>
                                                                    <img src={`data:image/svg+xml,${encodeURIComponent(
                                                                        item
                                                                    )}`} alt="" height={50} />
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className='pb-3'>
                                                <p className='d-flex pb-2'>COLORED OUTLINE</p>
                                                <div className='d-flex gap-3 flex-wrap all-icons'>
                                                    {icons && icons.colorOutlines.map((item: string, index: number) => {
                                                        return (
                                                            <>
                                                                <div key={`color-outline_${index}`} className={`icon-images-div ${isSelected === index + 'colorOutlines' ? "icon-images-div-active" : ""}`} onClick={() => { handleSelected(index + 'colorOutlines', item) }}>
                                                                    <img src={item} alt="" height={50} />
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className='pb-3'>
                                                <p className='d-flex pb-2'>FILLED</p>
                                                <div className='d-flex gap-3 flex-wrap all-icons'>
                                                    {icons && icons.filled.map((item: string, index: number) => {
                                                        return (
                                                            <>
                                                                <div className={`icon-images-div ${isSelected === index + 'filled' ? "icon-images-div-active" : ""}`} onClick={() => { handleSelected(index + 'filled', item) }}>
                                                                    <img src={item} alt="" height={50} />
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex w-100 justify-content-between'>
                                <button className="border-none onboarding-back upload-cancel" onClick={handleCloseModal}>Cancel</button>
                                <button className="border-none onboarding-checkout" onClick={handleUpload}>Choose</button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default ImageUploadModal