
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../../index';
import { AdminUserData } from '../../Interfaces/intefaces';

interface FetchAdminProfileDataArgs {
  userId: string;
  authToken: string;
}

// Define the return type for the Thunk action
interface AdminProfileData {
  userData: AdminUserData[]; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchAdminProfileData = createAsyncThunk<AdminProfileData, FetchAdminProfileDataArgs, { state: RootState }>(
  'profile/fetchAdminProfileData',
  async ({ userId, authToken }, { dispatch }) => {
    try {
      const ApiEndPoint = `${ApiUrl.user_profile.get_admin_profile}?userId=${userId}`;
      const response = await get(ApiEndPoint, authToken);

      if (response) {
        return response.data; // This value will be the payload of the fulfilled action
      } else {
        throw new Error('Failed to fetch Admin profile data');
      }
    } catch (error) {
      throw error; // This will trigger the rejected action
    }
  }
);
