import React from "react";
// hook
import { usePricing } from "./usePricing";
// components
import Monthly from "./Components/Monthly";
import Yearly from "./Components/Yearly";
import Footer from "../Footer/Footer";
import SignInHeader from "../../Components/Common/SignInHeader/SignInHeader";

// asstes
import images from "../../Assets/Images";
import "./Pricing.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import OnBoarding from "../OnBoarding/OnBoarding";

const Pricing = () => {
  const {
    isMonthly,
    handleButtonClick,
    faqItems,
    openAccordionIndex,
    handleAccordionToggle,
    navigate,
    setIsOnboarding,
    isOnboarding
  } = usePricing();
  return (
    <div>
      {
        !isOnboarding ? (<>
          <div className="pricing-page">
            <div className="first">
              <img src={images.PriceBackground} alt="" />
            </div>
            <SignInHeader btn='Investors' redirect='/investors'/>
            <div className={!isOnboarding ? "our" : "d-none"}>
              <h1 className="pricing-header">Our Pricing</h1>
              <p className="text-center mt-2 info">
                At CentiBlick we believe in transparent affordable pricing and
                customer service that exceeds expectations.
              </p>
            </div>
            <div className="second">
              <img src={images.PriceBackground} alt="" />
            </div>
          </div>


          <div className="d-flex justify-content-center flex-column">
            <div className="d-flex justify-content-center month-btn">
              <div className="buttons p-3 m-5">
                <button
                  className={`month ${isMonthly ? "active" : ""}`}
                  onClick={handleButtonClick}
                >
                  Monthly
                </button>
                <button
                  className={`month ${!isMonthly ? "active" : ""}`}
                  onClick={handleButtonClick}
                >
                  Yearly
                </button>
              </div>
            </div>
            <div className="year monthly">
              {isMonthly ? <Monthly setIsOnboarding={setIsOnboarding} /> : <Yearly setIsOnboarding={setIsOnboarding} />}
            </div>
            {/* <div className="year monthly">{isMonthly ? <Monthly /> : <Yearly />}</div> */}
          </div>
          <div className="d-flex bottom-content">
            <img src={images.PricingSideImage} alt="img" />
            <div className="content">
              <div className="main-content">
                <h1>Frequently asked questions</h1>
                <p className="mt-3">
                  Everything you need to know about the product and billing.
                </p>
              </div>
              <div className="side-content accordion mt-5" id="faqAccordion">
                {faqItems.map((item, index) => (
                  <div key={index}>
                    <h2
                      className="accordion-header d-flex justify-content-between"
                      id={`faqHeading${index}`}
                    >
                      <div className="que">{item.question}</div>
                      <button
                        data-bs-toggle="collapse"
                        data-bs-target={`#faqCollapse${index}`}
                        aria-expanded={openAccordionIndex === index}
                        aria-controls={`faqCollapse${index}`}
                        onClick={() => handleAccordionToggle(index)}
                      >
                        <FontAwesomeIcon
                          icon={openAccordionIndex === index ? faMinus : faPlus}
                          style={{ color: "#516cd3" }}
                          className="icon"
                        />
                      </button>
                    </h2>
                    <div
                      id={`faqCollapse${index}`}
                      className={`accordion-collapse collapse ${openAccordionIndex === index ? "show" : ""
                        }`}
                      aria-labelledby={`faqHeading${index}`}
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body">{item.answer}</div>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="text-center bottom-heading">
            <h1>Empower your patients and providers today!</h1>
            <div >
              <input type="text" placeholder="Your email address" />
              <button className="big-btn">Get Started Now</button>
            </div>
          </div>
          <Footer />
        </>) : (
          <OnBoarding />
        )
      }
    </div>
  );
};

export default Pricing;
