import React from "react";
import { useNavigate } from 'react-router-dom'

// assets
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import images from "../../Assets/Images";

// css
import './Footer.scss'

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigateOurPlatForm = (route: string) => {
    window.open(`https://www.centiblick.com/${route}`, "_blank")
  }

  const handleNavigateResources = (route: string) => {
    window.open(
      `https://www.centiblick.com/resources/categories/${route}`, "_blank")
  }

  const openExternalUrl = (url: string) => {
    window.open(url, "_blank");
  };

  const handleMediaNavigate = (route: 'linkedIn' | 'faceBook' | "instagram" | "twitter") => {
    switch (route) {
      case "linkedIn":
        openExternalUrl("https://www.linkedin.com/company/centiblick/?viewAsMember=true");
        break;
      case "faceBook":
        openExternalUrl("https://www.facebook.com/HealthInformationApp");
        break;
      case "instagram":
        openExternalUrl("https://www.instagram.com/centiblick/?igshid=OGQ5ZDc2ODk2ZA%3D%3D");
        break;
      case "twitter":
        openExternalUrl("https://twitter.com/centiblick?lang=en");
        break;
      default:
        break;
    }
  }
  
  return (
    <div className="footer">
      <div className="footerLogo">
        <div>
          <img src={images.LandingPageFooterLogo} alt="logo" className="logo" />
          <div className="icon">
            <FontAwesomeIcon
              className="media"
              icon={faLinkedin}
              color="#ffffff"
              onClick={() => handleMediaNavigate("linkedIn")}
            />
            <FontAwesomeIcon
              icon={faFacebook}
              className="media"
              color="#ffffff"
              onClick={() => handleMediaNavigate('faceBook')}
            />
            <FontAwesomeIcon
              icon={faInstagram}
              className="media"
              color="#ffffff"
              onClick={() => handleMediaNavigate('instagram')}
            />
            <FontAwesomeIcon
              icon={faTwitter}
              className="media"
              style={{ color: "#ffffff" }}
              onClick={() => handleMediaNavigate("twitter")}
            />
          </div>
        </div>
        <p className="disc">
          It's time to change <br /> lives for the good
        </p>
        <button
          className="btnFooter"
          onClick={() =>
            navigate('/investors')
          }
        >
          Investors
        </button>
      </div>
      <hr />
      <div className="footerTable d-flex justify-content-between">
        <div>
          <h1 >Our Platform</h1>
          <p
            onClick={() => handleNavigateOurPlatForm('patient-hybrid-care-environment')}
          >
            Patient Center
          </p>
          <p onClick={() => handleNavigateOurPlatForm('provider-virtual-care-center')}
          >
            Provider Center
          </p>
          <p onClick={() => handleNavigateOurPlatForm('telehealth-admin-center')}>
            Admin Center
          </p>
          <p onClick={() => handleNavigateOurPlatForm('telehealth-for-employees')}>
            Employee Center
          </p>
        </div>
        <div>
          <h1>Resources</h1>
          <p onClick={() => handleNavigateResources("telehealth-news-and-information")} >
            News Articles
          </p>
          <p onClick={() => handleNavigateResources("centiblick-announcements")}>
            Affiliate Resources
          </p>
          <p onClick={() => handleNavigateResources('telehealth-blog')} >
            Blog Updates
          </p>
          <p onClick={() => handleNavigateOurPlatForm("company-contact-and-information")}>
            Contact Information
          </p>
        </div>
        <div>
          <h1>Company</h1>
          <p onClick={() => handleNavigateOurPlatForm("security-trust-hipaa-compliant")} >
            Security & trust
          </p>
          <p onClick={() => handleNavigateOurPlatForm("terms")}>
            Terms of Use
          </p>
          <p onClick={() => handleNavigateOurPlatForm("privacy")} >
            Privacy Notice
          </p>
          <p onClick={() => handleNavigateOurPlatForm("help-and-support")} >
            Help and Support
          </p>
        </div>
      </div>
      <p className="last">© 2023 CentiBlick, Inc. All rights reserved.</p>
    </div>
  );
};

export default Footer;
