import React, { useState } from 'react'
import images from '../../../Assets/Images'
import { useNavigate } from 'react-router-dom';


const Monthly = ({setIsOnboarding} : any) => {
    const navigate = useNavigate()
    const [activeIndex, setActiveIndex] = useState(1);



    const handleClick = ()=>{
        navigate('/pricing/onboarding')
    }

    const handleMouseEnter = (index: any) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(1); // Set the default active index when leaving
    };
    return (
        <>
        <section>
            <div id='pricing' className='d-flex justify-content-center gap-4 monthly'>
                <div
                    onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={activeIndex === 0 ? 'active essential secondary d-flex justify-content-between flex-column' : 'essential secondary d-flex justify-content-between flex-column'}>

                    <div className="top">
                        <h1 className='header'>ESSENTIAL</h1>
                        <div className='price-details'>
                            <h1 className='price'>$69<span>/license/month</span></h1>
                            <p>Unlimited use</p>
                        </div >
                        <div className='details'>
                        <p className='heading mb-2'>30-Day Free Trial</p>
                            <div className='d-flex'>
                                <img src={images.Icon} alt='icon' />
                                <p className='d-inline'>Next-gen security: <span>keep your patients PHI and identity safe</span></p>
                            </div>
                            <div className='d-flex'>
                                <img src={images.Icon} alt='icon' />
                                <p className='d-inline'>Medical Records: <span>26,000+ connected facility EHRs instantly deliver providers the information they need</span></p>
                            </div>
                            <div className='d-flex'>
                                <img src={images.Icon} alt='icon' />
                                <p className='d-inline'>Zero Cost EHR Integration: <span>we will integrate your EHR at no additional charge (1-way)</span></p>

                            </div>
                            <div className='d-flex'>
                                <img src={images.Icon} alt='icon' />
                                <p className='d-inline'>Scheduling, Appointments, Digital Forms and Organization Branding: <span>support patients, providers and admins for a best-in-class virtual care experience</span></p>
                            </div>
                        </div>
                    </div>

                    {activeIndex === 0 ? 
                    <div className='contact' onClick={handleClick}>
                        Get Started - For Free
                    </div> : 
                    <div className='get-btn'>
                        Get Started - For Free
                    </div> }
                </div>
                <div
                    onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={activeIndex === 1 ? 'active essential d-flex justify-content-between flex-column' : 'essential d-flex justify-content-between flex-column'}
                >
                    <div className="top">
                        <h1 className='header'>ADVANCED</h1>
                        <div className='price-details'>
                            <h1 className='price'>$89<span>/license/month</span></h1>
                            <p>Unlimited use</p>
                        </div>
                        <div className='details'>
                            <p className='heading mb-2'>All Essential Features +</p>
                            <div className='d-flex'>
                                <img src={images.Icon} alt="icon" />
                                <p className='d-inline'>Digital Front Door: <span>deliver hybrid healthcare combining telehealth and your patient portal all in one place</span></p>
                            </div>
                            <div className='d-flex'>
                                <img src={images.Icon} alt="icon" />
                                <p className='d-inline'>Modern Referrals: <span>No more faxing, our platform supports HIPAA, ISO & Fed Ramp secure referrals</span></p>
                            </div>
                        </div>
                    </div>
                    {activeIndex === 1 ?
                        <div className='contact'>
                            Contact us
                        </div> :
                        <div className='get-btn'>
                            Contact us
                        </div>
                    }
                </div>
                <div

                    onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={handleMouseLeave} className={activeIndex === 2 ? 'active essential secondary d-flex justify-content-between flex-column' : 'essential secondary d-flex justify-content-between flex-column'}>
                    <div className="top">
                        <h1 className='header'>UPGRADES</h1>
                        <div className='price-details'>
                            <h1 className='price'>Quoted</h1>
                        </div>
                        <div className='details'>
                            <p className='heading mb-2'>Available Upgrades</p>
                            <div className='d-flex'>
                                <img src={images.Icon} alt="icon" />
                                <p className='d-inline'>2-Way EHR Integration</p>
                            </div>
                            <div className='d-flex'>
                                <img src={images.Icon} alt="icon" />
                                <p className='d-inline'>Auto-Transcribe</p>
                            </div>
                            <div className='d-flex'>
                                <img src={images.Icon} alt="icon" />
                                <p className='d-inline'>AI-Auto Charting with ICD-10, SNOMED & RX Norm NLP Integration</p>
                            </div>
                            <div className='d-flex'>
                                <img src={images.Icon} alt="icon" />
                                <p className='d-inline'>Remote Patient Monitoring with 4,000 pre-integrated devices</p>
                            </div>
                            <div className='d-flex'>
                                <div>
                                    <img src={images.Icon} alt="icon" />
                                </div>
                                <p className='d-inline'>e-Prescribe</p>
                            </div>
                        </div>
                    </div>
                    {activeIndex === 2 ?
                        <div className='contact'>
                            Request Quote
                        </div> :
                        <div className='get-btn'>
                            Request Quote
                        </div>}
                </div>
            </div>
        </section>
        </>
    )
}

export default Monthly