import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderProfileData } from "../../Thunk/getProfileData/providerProfileThunk";
import { getProvidersByValidSchedule } from "../../Thunk/getProfileData/getProvidersByValidSchedule";
export interface AppState {
  ProviderUserData: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  ProviderUserData: [],
  isLoading: false,
  error: null,
};

const ProviderProfileSlice = createSlice({
  name: "providerprofile",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviderProfileData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProviderProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ProviderUserData = action.payload;
      })
      .addCase(fetchProviderProfileData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getProvidersByValidSchedule.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProvidersByValidSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ProviderUserData = action.payload;
      })
      .addCase(getProvidersByValidSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

  },
});


export default ProviderProfileSlice;
