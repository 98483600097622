import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderListByAvailability } from "../../Thunk/SchedulingRulesProvider/getProviderListByAvailability";
import { waitingRoomPatientDataThunk } from "../../Thunk/ProviderChatSidebarDetail/waitingRoomPatientDataThunk";

export interface AppState {
    waitingRoomPatientData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    waitingRoomPatientData: null,
    isLoading: false,
    error: null,
};

const waitingRoomPatientDataSlice = createSlice({
    name: "waitingRoomPatientData",
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(waitingRoomPatientDataThunk.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(waitingRoomPatientDataThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.waitingRoomPatientData = action.payload;
            })
            .addCase(waitingRoomPatientDataThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default waitingRoomPatientDataSlice;
