import { createSlice } from "@reduxjs/toolkit";
import { fetchLeaveRequestData } from "../../Thunk/LeaveRequests/getAllLeaveRequestThunk";

interface LeaveEvent {
    event_id: string;
    from_date_time: string;
    image_path: string;
    leave_description: string;
    leave_posted_datetime: string;
    notification_type: string;
    provider_id: string;
    provider_name: string;
    status: string;
    to_date_time: string;
}

export interface AppState {
    LeaveRequests: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    LeaveRequests: [],
    isLoading: false,
    error: null,
};

const LeaveRequestSlicer = createSlice({
    name: "leaveRequest",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaveRequestData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchLeaveRequestData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.LeaveRequests = action.payload;
            })
            .addCase(fetchLeaveRequestData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default LeaveRequestSlicer;
