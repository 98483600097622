import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppState {
  themeColor: string;
  language: string;
  detailsPageTitle: string;
  plan: string;
  providerPlan: string;
  adminPlan: string;
  isUserSignIn: boolean;
  seletedId: string;
  socketProviderData: any;
  providerHome:boolean;
  ccpInitialize:boolean;
  chatConnected:boolean;
}

const initialState: AppState = {
  themeColor: "blue",
  language: "en",
  detailsPageTitle: "",
  plan: "enterprise",
  providerPlan: "enterprise",
  adminPlan: "essential",
  isUserSignIn: false,
  seletedId: "",
  socketProviderData:[],
  providerHome : false,
  ccpInitialize:false,
  chatConnected:false,
};


export const commonSlicer = createSlice({
  name: "app",
  initialState,
  reducers: {
    reset: () => initialState,
    getTheme: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.themeColor = action.payload;
    },
    setLanguage: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.language = action.payload;
    },
    setDetailsPageTitle: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.detailsPageTitle = action.payload;
    },
    setPlan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.plan = action.payload;
    },
    setProviderplan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.providerPlan = action.payload;
    },
    setAdminplan: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.adminPlan = action.payload;
    },
    setIsUserSignIn: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.isUserSignIn = action.payload;
    },
    setSeletedId: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.seletedId = action.payload;
    },
    setSocketProviderData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<string>
    ) => {
      state.socketProviderData = action.payload;
    },
    setIsProviderHome: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.providerHome = action.payload;
    },
    setCcpInitialize: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.ccpInitialize = action.payload;
    },
    setChatConnected: (
      state: Draft<typeof initialState>,
      action: PayloadAction<boolean>
    ) => {
      state.chatConnected = action.payload;
    },
  },
});

export const { getTheme, setLanguage, setDetailsPageTitle, setPlan, setProviderplan, setAdminplan, setIsUserSignIn, reset, setSeletedId,setSocketProviderData,setIsProviderHome,setCcpInitialize,setChatConnected } =
  commonSlicer.actions;
