import { createSlice } from "@reduxjs/toolkit";
import { fetchLeaveRequestDataOfProvider } from "../../Thunk/LeaveRequests/getLeaveRequestByProviderThunk";

interface LeaveByProviderEvent {
    leave_posted_datetime: string;
    status: string;
    event_id: string;
    image_path: string;
    provider_id: string;
    leave_description: string;
    provider_name: string;
    to_date_time: string;
    notification_type: string;
    from_date_time: string;
}

export interface AppState {
    LeaveRequestsDataByProvider: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    LeaveRequestsDataByProvider: [],
    isLoading: false,
    error: null,
};

const LeaveRequestDataByProviderSlicer = createSlice({
    name: "LeaveRequestDataByProvider",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaveRequestDataOfProvider.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchLeaveRequestDataOfProvider.fulfilled, (state, action) => {
                state.isLoading = false;
                state.LeaveRequestsDataByProvider = action.payload;
            })
            .addCase(fetchLeaveRequestDataOfProvider.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});
export default LeaveRequestDataByProviderSlicer;
