import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppState {
  procedures_data: any[];
}

const initialState: AppState = {
  procedures_data: [],
};

export const proceduresSection = createSlice({
  name: "proceduresSection",
  initialState,
  reducers: {
    proceduresData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.procedures_data = action.payload;
    },
    resetState: () => initialState
  },
});

export const { proceduresData,resetState } = proceduresSection.actions;
