// const getBaseUrl = () => {
//   // eslint-disable-next-line no-undef
//   const dev = process.env.NODE_ENV === 'development';
//   if (dev) {
//     return `${process.env.REACT_APP_DEVELOPMENT_BASE_URL}/api/v1`;
//   }
//   return `${process.env.REACT_APP_PRODUCATION_BASE_URL}/api/v1`;
// };
// const BASE_URL = getBaseUrl();

// const BASE_URL = process.env.REACT_APP_DEVELOPMENT_BASE_URL;
const BASE_URL = process.env.REACT_APP_PRODUCTION_BASE_URL;
const NODE_BASE_URL = process.env.REACT_APP_NODE_PROD_BASE_URL;
const CONNECT_BASE_URL = process.env.REACT_APP_CONNECT_BASE_URL;

export const ApiUrl = {
  // authModule: {
  //   user_login: `${BASE_URL}/user/login/`,
  //   user_signup: `${BASE_URL}/user/signup/`,
  // },
  accounts: {
    get_accounts_info: `${BASE_URL}/get-accounts-info`,
    update_account_info_for_user: `${BASE_URL}/update-account-info-for-user`,
    get_account_id_from_user_id: `${BASE_URL}/get-account-id-from-user-id`,
  },
  user_profile: {
    store_profile_information: `${NODE_BASE_URL}/store-profile-information`,
    store_user_images: `${NODE_BASE_URL}/store-profile-information`,
    get_patient_profile: `${CONNECT_BASE_URL}/get-patient-profile`,
    get_filtered_patient_profile: `${CONNECT_BASE_URL}/get-patient-filter`,
    save_patient_profile: `${CONNECT_BASE_URL}/save-patient-profile`,
    get_provider_profile: `${CONNECT_BASE_URL}/get-provider-profile`,
    save_provider_profile: `${CONNECT_BASE_URL}/save-provider-profile`,
    get_admin_profile: `${CONNECT_BASE_URL}/get-admin-profile`,
    save_admin_profile: `${CONNECT_BASE_URL}/save-admin-profile`,
    delete_profile: `${CONNECT_BASE_URL}/delete-user`,
    delete_patient_profile: `${CONNECT_BASE_URL}/delete-patient-profile`,
    delete_provider_profile: `${CONNECT_BASE_URL}/delete-provider-profile`,
    delete_admin_profile: `${CONNECT_BASE_URL}/delete-admin`,
    reset_password: `${CONNECT_BASE_URL}/trigger-forgot-password`,
  },
  connect_my_medical_records: {
    get_all_health_systems: `${BASE_URL}/get-all-health-systems`,
    get_auth_page: `${BASE_URL}/get-auth-page`,
    initiate_audit_request: `${BASE_URL}/initiate-audit-request`,
    get_audit_status: `${BASE_URL}/get-audit-status`,
    load_user_data: `${BASE_URL}/load-user-data`,
    get_data_load_status: `${BASE_URL}/get-data-load-status`,
  },
  share_my_medical_records: {
    get_practitioner_details: `${BASE_URL}/get-practitioner-details`,
    generate_link: `${BASE_URL}/generate-link`,
    share_to_email: `${BASE_URL}/send-email`,
  },
  profile: {
    get_patient_demographics_data: `${BASE_URL}/get-patient-demographic-data`,
    get_allergies_intolerance_data: `${BASE_URL}/get-allergies-intolerance-data`,
    get_vaccinations_data: `${BASE_URL}/get-vaccination-data`,
    get_smoking_status_data: `${BASE_URL}/get-smoking-status-data`,
  },
  care_teams: {
    get_care_teams_data: `${BASE_URL}/get-care-team-data`,
    get_practitioner_teams_data: `${BASE_URL}/get-practitioner-data`,
  },
  care_history: {
    get_conditions_data: `${BASE_URL}/get-conditions-data`,
    get_encounters_data: `${BASE_URL}/get-encounters-data`,
    get_treatment_plan_data: `${BASE_URL}/get-treatment-plans-data`,
  },
  medications: {
    get_medication_data: `${BASE_URL}/get-medication-data`,
  },
  procedures: {
    get_procedures_data: `${BASE_URL}/get-procedures-data`,
    // get_devices_data: `${BASE_URL}/get-devices-data`,
  },
  health_testing: {
    get_health_screen_data: `${BASE_URL}/get-health-screen-data`,
    get_lab_test_data: `${BASE_URL}/get-lab-test-data`,
    get_lab_panels_data: `${BASE_URL}/get-lab-panel-data`,
    get_lab_vitals_data: `${BASE_URL}/get-vitals-data`,
  },
  user_messages: {
    get_messages: `${NODE_BASE_URL}/get-message`,
  },
  schedule_appointment: {
    create_appointment: `${NODE_BASE_URL}/schedule-appointment`,
  },
  services: {
    get_service: `${CONNECT_BASE_URL}/get-services`,
    delete_service: `${CONNECT_BASE_URL}/delete-service`,
    create_service: `${CONNECT_BASE_URL}/create-service`,
  },
  scheduling: {
    get_schedule: `${CONNECT_BASE_URL}/get-schedule`,
    create_schedule: `${CONNECT_BASE_URL}/create-schedule`,
    delete_schedule: `${CONNECT_BASE_URL}/delete-schedule`,
    update_schedule: `${CONNECT_BASE_URL}/update-schedule`,
  },
  adminSchedule:{
    get_admin_schedule: `${CONNECT_BASE_URL}/get-schedule-by-admin-datetime`,
    create_admin_schedule: `${CONNECT_BASE_URL}/create-admin-schedule`,
    update_admin_schedule: `${CONNECT_BASE_URL}/update-admin-schedule`,
    delete_admin_schedule: `${CONNECT_BASE_URL}/delete-admin-schedule`,
  },
  appointments: {
    create_appointment: `${CONNECT_BASE_URL}/create-appointment`,
    get_patient_appointments: `${CONNECT_BASE_URL}/get-patient-appointments`,
    get_provider_appointments: `${CONNECT_BASE_URL}/get-provider-appointments`,
    get_patient_appointments_all_included: `${CONNECT_BASE_URL}/get-patient-appointments-all-included`,
    cancel_appointment: `${CONNECT_BASE_URL}/update-appointment`,

    // appotment  date view
    get_provider_date_view: `${CONNECT_BASE_URL}/get-provider-date-view`,
    get_patient_date_view: `${CONNECT_BASE_URL}/get-patient-date-view`,
    get_appoinment_by_status: `${CONNECT_BASE_URL}/get-cancelled-appointments`,
    get_appointment_by_date_status : `${CONNECT_BASE_URL}/get-appointment-by-date-status`,

    // apis based on service
    services_api:{
      get_provider_date_view_by_service:`${CONNECT_BASE_URL}/get-provider-date-view-by-service`,
      get_provider_appointments_by_service:`${CONNECT_BASE_URL}/get-provider-appointment-details-by-service`,
      get_providers_by_valid_schedule:`${CONNECT_BASE_URL}/get-providers-by-valid-schedule`,
    },
    get_patient_upcoming_appointments: `${CONNECT_BASE_URL}/get-patient-appt-by-from-to-date`,
    get_provider_by_service : `${CONNECT_BASE_URL}/get-providers-by-service`,
  },
  leave_requests: {
    get_all_leave_requests: `${CONNECT_BASE_URL}/get-all-leave-request`,
    get_leave_request_by_provider: `${CONNECT_BASE_URL}/get-leave-request-by-provider`,
    // create_leave_request: `${CONNECT_BASE_URL}/create-leave-request`,
    get_leave_request_by_date: `${CONNECT_BASE_URL}/get-leave-request-by-date`,
    // approve_leave_request: `${CONNECT_BASE_URL}/approve-leave-request`,
  },
  provider_scheduling_rules: {
    create_scheduling_rule: `${CONNECT_BASE_URL}/create-scheduling-rule`,
    get_scheduling_rule_by_provider: `${CONNECT_BASE_URL}/get-scheduling-rule`,
    get_provider_list_by_availability: `${CONNECT_BASE_URL}/get-provider-list-by-availability`,
  },
  userShift:{
    get_provider_by_id : `${CONNECT_BASE_URL}/get-schedule-by-provider-datetime`,
    get_provider_Service : `${CONNECT_BASE_URL}/get-services-with-active-provider-schedule`
  },
  myForms:{
    patient_information:{
      patient_get_information: `${CONNECT_BASE_URL}/get-patient-info-form`,
      patient_create_information: `${CONNECT_BASE_URL}/create-patient-info`,
      patient_update_information: `${CONNECT_BASE_URL}/update-patient-info-form`,
      patient_delete_information: `${CONNECT_BASE_URL}/delete-patient-info-form`,
    },
    patient_history:{
      patient_get_history: `${CONNECT_BASE_URL}/get-patient-history-form`,
      patient_create_history: `${CONNECT_BASE_URL}/create-patient-history`
    },
    patient_hippa_consent:{
      patient_get_hippa_consent: `${CONNECT_BASE_URL}/get-hippa-consent-form`,
      patient_create_hippa_consent: `${CONNECT_BASE_URL}/create-hippa-consent-form`
    },
    patient_telehealth_consent:{
      patient_get_telehealth_consent: `${CONNECT_BASE_URL}/get-telehealth-consent-form`,
      patient_create_telehealth_consent: `${CONNECT_BASE_URL}/create-telehealth-consent-form`
    },
    patient_information_release:{
      patient_get_information_release : `${CONNECT_BASE_URL}/get-information_release-form`,
      patient_create_information_release : `${CONNECT_BASE_URL}/create-information_release-form`
    },
  },
  send_email:{
    send_mail_url:`${CONNECT_BASE_URL}/send-notification`
  },
  contact_history:{
    provider_contact_history:`${CONNECT_BASE_URL}/get-provider-contact-history`
  }
};
