
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../../index';

interface FetchPatientProfileDataArgs {
  userId?: string;
  authToken?: string;
  type?: 'FilteredProfile';
}

// Define the return type for the Thunk action
interface PatientProfileData {
  userData: {
    address: string;
    dob: string;
    email: string;
    emergency_contact_name: string;
    emergency_contact_number: string;
    first_name: string;
    gender: string;
    group_number: string;
    insurance: string;
    last_name: string;
    middle_name: string;
    phone: string;
    policy_number: string;
    profile_image_path: string;
    license_image_path: string;
    insurance_image_path: string;
    user_id: string;
  }[]
}

export const fetchPatientProfileData = createAsyncThunk<PatientProfileData, FetchPatientProfileDataArgs, { state: RootState }>(
  'profile/fetchPatientProfileData',
  async ({ userId, authToken, type }, { dispatch }) => {
    try {
      let ApiEndPoint;
      if (userId) {
        // Use the API with userId
        ApiEndPoint = `${ApiUrl.user_profile.get_patient_profile}?userId=${userId}`;
      } else if (type) {
        ApiEndPoint = `${ApiUrl.user_profile.get_filtered_patient_profile}`;
      } else {
        // Use the API without userId
        ApiEndPoint = `${ApiUrl.user_profile.get_patient_profile}`;
      }
      // const ApiEndPoint = `${ApiUrl.user_profile.get_patient_profile}?userId=${userId}`;
      const response = await get(ApiEndPoint, authToken);

      if (response) {
        return response.data; // This value will be the payload of the fulfilled action
      } else {
        throw new Error('Failed to fetch patient profile data');
      }
    } catch (error) {
      throw error; // This will trigger the rejected action
    }
  }
);
